import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    isDateBefore,
} from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import FocusError from 'components/FocusError'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
} from 'components/formFields'
import Note from 'components/Note'

const FormalEducationForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        schoolName: initialData?.schoolName ?? '',
        degree: initialData?.degree ?? '',
        fieldOfStudy: initialData?.fieldOfStudy ?? '',
        enrolled: initialData?.enrolled ?? '',
        completed: initialData?.completed ?? '',
        stillEnrolled: initialData?.stillEnrolled ?? false,
        files: initialData?.files ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        schoolName: Yup.string().trim().required(requiredMessage),
        degree: Yup.string().trim().required(requiredMessage),
        fieldOfStudy: Yup.string().trim().required(requiredMessage),
        enrolled: Yup.string()
            .nullable()
            .test('enrolled', (value, { createError }) => {
                if (!value) {
                    return true
                }

                if (
                    !getIsDateSameOrBeforeCurrentDate(
                        value,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.enrolledDateInFuture'),
                        path: 'enrolled',
                    })
                }

                return true
            }),
        completed: Yup.string()
            .nullable()
            .test('completed', (value, { parent, createError }) => {
                if (parent.stillEnrolled === true) {
                    return true
                }

                if (!value) {
                    return createError({
                        message: requiredMessage,
                        path: 'completed',
                    })
                }

                if (
                    !getIsDateSameOrBeforeCurrentDate(
                        value,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.completedDateInFuture'),
                        path: 'completed',
                    })
                }

                if (!parent.enrolled) {
                    return true
                }

                if (
                    isDateBefore(
                        value,
                        parent.enrolled,
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return createError({
                        message: t('form.error.completedDateBeforeEnrolled'),
                        path: 'completed',
                    })
                }
                return true
            }),
        stillEnrolled: Yup.boolean().required(requiredMessage),
        files: Yup.array().notRequired(),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
            })

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => setOpen(false)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, values, setFieldValue, setFieldTouched }) => {
                return (
                    <Form className="-form">
                        <FocusError />
                        <Modal
                            open={open}
                            setOpen={setOpen}
                            closeOnClickOutside={false}
                            title={title || ''}
                            isSubmitting={isSubmitting}
                            buttons={{
                                nextBtn: {
                                    label: 'button.saveFormalEducation',
                                },
                            }}
                        >
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12 _m6">
                                        <InputField
                                            name="schoolName"
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField name="degree" required />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField
                                            name="fieldOfStudy"
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <DateTimeField
                                            name="enrolled"
                                            icon={true}
                                            dateFormat="MMM/y"
                                            monthPicker
                                            maxDate={
                                                values.completed
                                                    ? new Date(values.completed)
                                                    : getTodaysDate()
                                            }
                                        />
                                    </div>
                                    <div className="_12 _s8 _m6">
                                        <DateTimeField
                                            name="completed"
                                            icon={true}
                                            disabled={
                                                values.stillEnrolled === true
                                            }
                                            required={
                                                values.stillEnrolled !== true
                                            }
                                            dateFormat="MMM/y"
                                            monthPicker
                                            maxDate={getTodaysDate()}
                                            minDate={
                                                values.enrolled
                                                    ? new Date(values.enrolled)
                                                    : null
                                            }
                                        />
                                    </div>
                                    <div className="_12 _s4 _m6 centered-checkbox -withSelect">
                                        <CheckboxField
                                            name="stillEnrolled"
                                            label="form.label.stillEnrolled"
                                            translate
                                            onChange={(e) => {
                                                const newValue =
                                                    e.target.checked
                                                setFieldValue(
                                                    'stillEnrolled',
                                                    newValue
                                                )

                                                if (newValue === true) {
                                                    setFieldValue(
                                                        'completed',
                                                        null
                                                    )
                                                    setFieldTouched(
                                                        'completed',
                                                        false
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                    <FileUploadField
                                        name="files"
                                        label="form.label.uploadDiploma"
                                        fileCategory={
                                            FILE_CATEGORIES.FORMAL_EDUCATION
                                        }
                                    />
                                    <div className="_12 -mt20">
                                        <Note note="message.verificationOfTraining" />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </Form>
                )
            }}
        </Formik>
    )
}

FormalEducationForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
}

export default FormalEducationForm
