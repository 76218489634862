import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import PillItem from './PillItem'

const FilterButton = ({ value, filter, handleRemove }) => {
    const t = useTranslate()

    const { icon, unit, translateUnit, displayAttribute, formatter } = filter

    const getFilterValue = () => {
        if (formatter) return formatter(value, filter, t)
        if (displayAttribute) return value[displayAttribute]
        return value
    }

    const getUnit = () => {
        if (!unit) return ''
        return translateUnit ? t(unit) : unit
    }

    return (
        <div className="a-captionsTextRegular">
            <PillItem
                renderCustomValue={() => `${getFilterValue()} ${getUnit()}`}
                handleRemove={handleRemove}
                icon={icon}
            />
        </div>
    )
}

FilterButton.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filter: PropTypes.object,
    handleRemove: PropTypes.func,
}

FilterButton.defaultProps = {
    filter: {},
}

export default FilterButton
