import { PROJECT_STATUS } from 'constants/enums'

// returns value of object property defined by key
const getValueByKey = (key, item) => {
    let retVal = null
    if (key.includes('.')) {
        const splitedKeyValues = key.split('.')
        splitedKeyValues.forEach((keyValue) => {
            if (!retVal) {
                retVal = item[keyValue]
            } else {
                retVal = retVal[keyValue]
            }
        })
    } else {
        retVal = item[key]
    }
    return retVal
}

const getProjectStatusColor = (status) => {
    switch (status) {
        case PROJECT_STATUS.COMPLETED:
            return 'green'
        case PROJECT_STATUS.IN_PROGRESS_EXECUTING:
            return 'orange'
        case PROJECT_STATUS.MOBILIZING_PREPARING:
            return 'purple'
        case PROJECT_STATUS.PLANNING:
            return 'gray'
        default:
            return 'gray'
    }
}

export { getValueByKey, getProjectStatusColor }
