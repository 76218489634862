import React, { useContext, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import { createEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'

import FocusError from './FocusError'
import Modal from './Modal'
import { SelectField } from './formFields'
import FieldLabel from './FieldLabel'
import Loader from './Loader'
import Avatar from './Avatar'

const InviteDiverToProjectFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { project } = values

    useEffect(() => {
        setFieldValue('position', null)
        setFieldTouched('position', false)
    }, [project])
}

const InviteDiverToProject = ({ diver, handleClose }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        project: null,
        position: null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        project: Yup.object().required(requiredMessage),
        position: Yup.object().required(requiredMessage),
    })

    const onSubmit = async ({ position }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await createEntityService(
                ENTITIES.INVITE_USER,
                {
                    divers: [
                        {
                            id: diver.id,
                        },
                    ],
                    projectPositionId: position.id,
                },
                false
            )
            setAlert(
                t('message.invitationSuccessfullySentToTheDiver'),
                ALERT_TYPES.SUCCESS
            )
            handleClose()
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ values: { project, position }, isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={handleClose}
                        closeOnClickOutside={false}
                        title="general.inviteDiverToAProject"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                            },
                            nextBtn: {
                                label: 'button.sendInvitation',
                                tooltip:
                                    'button.pleaseSelectProjectAndPosition',
                                disabled: !project || !position,
                            },
                        }}
                        smallModalWithDropdowns
                    >
                        <InviteDiverToProjectFormContext />
                        <div className="_12 column -gap10 -mb20">
                            <FieldLabel label={`form.label.userSelected`} />
                            <Avatar
                                avatar={diver?.avatarPath}
                                fullName={diver?.fullName}
                                size={ICON_SIZE.SIZE40}
                                centered={false}
                                showFullName={true}
                            />
                        </div>
                        <div className="_12">
                            <SelectField
                                name="project"
                                placeholder="form.placeholder.selectProject"
                                entityType={ENTITIES.PROJECT_ELASTIC}
                                params={{
                                    myDiveProjects: true,
                                    createdByMyCompany: true,
                                    canInviteUser: diver.id,
                                }}
                                noDataNoteMessage="general.noAvailableProjectsToInviteDiver"
                                required
                                searchable
                            />
                        </div>
                        {!!project && (
                            <div className="_12">
                                <SelectField
                                    name="position"
                                    placeholder="form.placeholder.selectPosition"
                                    entityType={ENTITIES.PROJECT_POSITIONS}
                                    params={{
                                        'project.id': project?.id,
                                        include: 'diverPosition',
                                        onlyOpened: true,
                                    }}
                                    noDataNoteMessage="general.noAvailablePositionsForSelectedProject"
                                    displayAttribute="diverPosition.name"
                                    searchAttribute="diverPosition.name"
                                    reload={project}
                                    required
                                    searchable
                                />
                            </div>
                        )}
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default InviteDiverToProject
