import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'
import useQueryParams from 'hooks/useQueryParams'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Modal from 'components/Modal'
import TabNavigation from 'components/tabs/TabNavigation'
import CardHeader from 'components/card/CardHeader'

const DiveTeamMembersList = ({
    diveTeam,
    meta,
    isLoading,
    isProjectOwner,
    isProjectClient,
}) => {
    const t = useTranslate()
    const { isSmallScreen, isSmallerTablet } = useWindowDimensions()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)

    const { tab } = useQueryParams()

    const { id } = useParams()

    const { userId } = useContext(CurrentUserContext)

    const [activeTabContent, setActiveTabContent] = useState()

    // If the current user is a diver, he  can see team members only if he is employed on the project
    const canSeeTeamMembers =
        isProjectOwner ||
        isProjectClient ||
        !!diveTeam.find((item) => item.workerId === userId)

    useEffect(() => {
        if (tab) {
            const activeTabList = diveTeam.filter(
                (item) => item.positionTitleCode === tab
            )
            setActiveTabContent(activeTabList)
        }
    }, [tab])

    useEffect(() => {
        const firstPositionName = diveTeam[0]?.positionTitleCode
        const firstPositionContent = diveTeam.filter(
            (item) => item.positionTitleCode === firstPositionName
        )

        setActiveTabContent(firstPositionContent)
    }, [diveTeam])

    const renderTabContent = () => {
        if (activeTabContent) {
            return activeTabContent.map((item) => {
                return (
                    <div className="_12 _l4 -mb10" key={item.id}>
                        <CardContainer
                            link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                        >
                            <CardHeader
                                title={item.fullName}
                                subtitle={[
                                    item.positionTitle,
                                    item.countryName,
                                ]}
                                avatar={item.avatarPath}
                                placeholderIcon={ICONS.USER}
                                verifiedBadge={!!item.identityVerified}
                                onlyHeader={true}
                            />
                        </CardContainer>
                    </div>
                )
            })
        }
        return null
    }

    const handleViewDiveTeam = () => {
        navigate(`${ROUTES.PROJECT}/${id}${ROUTES.DIVE_TEAM}`)
    }

    const getContent = (employees) => {
        if (!employees.length) {
            return <Fragment />
        }

        return employees.slice(0, 5).map((item) => (
            <div className="-mt20 fullWidth" key={item.id}>
                <CardContainer
                    noBorderCard
                    link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                >
                    <CardHeader
                        title={item.fullName}
                        subtitle={item.positionTitle}
                        avatar={item.avatarPath}
                        placeholderIcon={ICONS.USER}
                        verifiedBadge={!!item.identityVerified}
                        link={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                        onlyHeader={true}
                    />
                </CardContainer>
            </div>
        ))
    }

    const tabData = diveTeam
        .reduce((positions, position) => {
            const existingItem = positions.find(
                (item) => item.key === position.positionTitleCode
            )

            if (!existingItem) {
                const count = diveTeam.filter(
                    (item) =>
                        item.positionTitleCode === position.positionTitleCode
                ).length

                const tabItem = {
                    key: position.positionTitleCode,
                    title: `${count} ${
                        count > 1
                            ? `${position.positionTitle}s`
                            : position.positionTitle
                    }`,
                    originalTitle: position.positionTitle,
                    hidden: count === 0,
                }

                positions.push(tabItem)
            }

            return positions
        }, [])
        .sort((p1, p2) => {
            return p1.originalTitle > p2.originalTitle ? 1 : -1
        })

    const canViewTeamMembersButton = diveTeam.length > 5
    const canViewGoToTeamManagementButton = isProjectOwner || isProjectClient

    const viewTeamMembersButton = canViewTeamMembersButton && (
        <Button
            btnClass={BUTTON_STATUS.SECONDARY}
            buttonSize={BUTTON_SIZE.SMALL}
            label="button.viewTeamMembers"
            onClick={() => {
                setOpenModal(true)
            }}
            icon={ICONS.ARROW_RIGHT}
            iconColor={COLORS.LIGHT_BLUE}
            iconPosition={ICON_POSITION.RIGHT}
        />
    )

    const goToTeamManagementButton = canViewGoToTeamManagementButton && (
        <Button
            btnClass={BUTTON_STATUS.PRIMARY}
            buttonSize={BUTTON_SIZE.SMALL}
            label="button.goToTeamManagement"
            onClick={handleViewDiveTeam}
            icon={ICONS.ARROW_RIGHT}
            iconColor={COLORS.WHITE}
            iconPosition={ICON_POSITION.RIGHT}
        />
    )

    const buttonsFlexDirectionSmallScreen = isSmallerTablet ? 'column' : 'row'

    return (
        <Fragment>
            <div className="column fullWidth">
                <span className="a-bodyTextMedium">
                    {t('general.teamMembers')}
                </span>
                {diveTeam.length !== 0 && (
                    <p className="a-mediumText a-lightText -opacity-60 -mt10">
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.thereIs'
                                    : 'general.thereAre'
                            }`
                        )}{' '}
                        {meta.totalItems}{' '}
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.teamMemberOnProject'
                                    : 'general.teamMembersOnProject'
                            }`
                        )}
                    </p>
                )}

                {getContent(diveTeam)}
                {diveTeam.length === 0 && !isLoading && (
                    <span className="a-mediumText a-lightText fullWidth -mt10">
                        {canSeeTeamMembers
                            ? t('general.noTeamMembers')
                            : t('general.teamVisibleToEmployedMembers')}
                    </span>
                )}

                {(canViewTeamMembersButton ||
                    canViewGoToTeamManagementButton) && (
                    <div
                        className={`${isSmallScreen ? buttonsFlexDirectionSmallScreen : 'column'} -gap10 -mt30 fullWidth`}
                    >
                        {viewTeamMembersButton}
                        {goToTeamManagementButton}
                    </div>
                )}
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    setOpen={setOpenModal}
                    closeOnClickOutside={false}
                    title="general.teamMembers"
                >
                    <div className="fullWidth">
                        <div className="_12">
                            <TabNavigation
                                tabs={tabData}
                                tabQueryParam="tab"
                                translate={false}
                            />
                        </div>

                        <div className="fullWidth aligned-center flex-wrap">
                            {renderTabContent()}
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

DiveTeamMembersList.propTypes = {
    projectId: PropTypes.number,
}

export default DiveTeamMembersList
