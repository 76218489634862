import PropTypes from 'prop-types'

const SingleStatus = ({ statusColor = '-gray', item }) => {
    return (
        <span className={`a-status a-captionsTextRegular ${statusColor}`}>
            {item.name || item}
        </span>
    )
}

SingleStatus.propTypes = {
    item: PropTypes.string,
    statusColor: PropTypes.string,
}

export default SingleStatus
