import { Form, Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    checkIfExpired,
    getIsDateSameOrBeforeCurrentDate,
    getIsMonthSameOrAfter,
    getTodaysDate,
    isDateAfter,
    isMonthAfter,
} from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatRequestData } from 'utils/jsonApiFormatters'
import {
    validateEmail,
    validatePhoneNumber,
    validatePhoneNumberNotRequired,
} from 'utils/yupValidations'

import useQueryParams from 'hooks/useQueryParams'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    PRIMARY_TRAINING_TYPE,
    PRIMARY_TRAINING_TYPE_CATEGORY,
    WALLET_CERTIFICATE_GENERATION_STATUSES,
} from 'constants/enums'
import ICONS from 'constants/icons'
import { FILE_CATEGORIES } from 'constants/fileCategories'
import REGEXP from 'constants/regex'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    DateTimeField,
    FileUploadField,
    InputField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import ModalButtons from 'components/modal/ModalButtons'
import ModalTitle from 'components/modal/ModalTitle'
import { getTrainingTypeForSubTab } from 'screens/diver/profile/diverInformation/constants/trainingRecordOptions'
import CertificateCard from './CertificateCard'
import CertificateForm from './CertificateForm'
import CardHeader from 'components/card/CardHeader'
import Note from 'components/Note'
import Accordion from 'components/Accordion'
import CertificateQrCode from './CertificateQrCode'

import {
    canDeleteCertificate,
    canEditCertificate,
    canViewQRCode,
    getCertificateStatus,
    isADCI,
} from '../utils/certificateHelper'
import AddCertificateToWalletViaEmail from './AddCertificateToWalletViaEmail'

const { OTHER_TRAINING } = PRIMARY_TRAINING_TYPE
const { COMMERCIAL, MILITARY, OTHER } = PRIMARY_TRAINING_TYPE_CATEGORY

const TrainingCourseFormContext = ({ showAdditionalSchoolFields }) => {
    const formikContext = useFormikContext()
    const {
        values,
        setFieldValue,
        setFieldTouched,
        setValues,
        setTouched,
        touched,
    } = formikContext
    const { primaryTrainingType, primaryTrainingTypeCategory, diveSchool } =
        values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            setFieldValue('primaryTrainingTypeCategory', null)
            setFieldTouched('primaryTrainingTypeCategory', false)
        }
    }, [primaryTrainingType])

    useEffect(() => {
        if (!firstRender.current && showAdditionalSchoolFields) {
            setTouched({
                ...touched,
                schoolAddress: false,
                schoolState: false,
                schoolEmail: false,
                schoolPhoneNumber: false,
                phoneCode: false,
            })
            const { address, country, countryPhoneCode, email, phoneNumber } =
                diveSchool?.contactAddress || {}
            setValues({
                ...values,
                schoolAddress: address,
                schoolState: country,
                schoolEmail: email,
                schoolPhoneNumber: phoneNumber,
                phoneCode: countryPhoneCode,
            })
        }
    }, [diveSchool])

    useEffect(() => {
        if (!firstRender.current) {
            if (primaryTrainingTypeCategory?.id === MILITARY) {
                setTouched({
                    ...touched,
                    schoolAddress: false,
                    schoolState: false,
                    schoolEmail: false,
                    schoolPhoneNumber: false,
                    phoneCode: false,
                })
                setValues({
                    ...values,
                    schoolAddress: '',
                    schoolState: null,
                    schoolEmail: '',
                    schoolPhoneNumber: '',
                    phoneCode: null,
                })
            }

            if (primaryTrainingTypeCategory?.id !== MILITARY) {
                setFieldValue('country', null)
                setFieldTouched('country', false)

                setFieldValue('branch', null)
                setFieldTouched('branch', false)
            }

            setFieldValue('diveSchool', null)
            setFieldTouched('diveSchool', false)

            setFieldValue('diveSchoolName', '')
            setFieldTouched('diveSchoolName', false)

            setFieldValue('trainingTypes', [])
            setFieldTouched('trainingTypes', false)
        } else {
            firstRender.current = false
        }
    }, [primaryTrainingTypeCategory])

    return null
}

const TrainingCourseForm = ({ handleSubmit, initialData, setOpen, open }) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { subTab } = useQueryParams()

    const [primaryTrainingType] = useState(getTrainingTypeForSubTab(subTab))
    const [submitted, setSubmitted] = useState(false)
    const [editCertificateData, setEditCertificateData] = useState(null)
    const [editCertificateIndex, setEditCertificateIndex] = useState(null)
    const [certificateQR, setCertificateQR] = useState(null)
    const [sendCertificateToEmail, setSendCertificateToEmail] = useState(null)

    const formRef = useRef()

    const [certificates, setCertificates] = useState(
        initialData?.trainingCertificates ?? []
    )
    const [showCertificateForm, setShowCertificateForm] = useState(false)

    const handleDelete = async (index) => {
        try {
            const certificatesCopy = [...certificates]
            certificatesCopy.splice(index, 1)
            setCertificates(certificatesCopy)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleEdit = (item, index) => {
        setShowCertificateForm(true)
        setEditCertificateData(item)
        setEditCertificateIndex(index)
    }

    const initialValues = {
        primaryTrainingType:
            initialData?.primaryTrainingType ??
            getTrainingTypeForSubTab(subTab),
        primaryTrainingTypeCategory:
            initialData?.primaryTrainingTypeCategory ?? null,
        diveSchool: initialData?.diveSchool ?? null,
        diveSchoolName: initialData?.diveSchoolName ?? '',
        country: initialData?.country ?? null,
        branch: initialData?.branch ?? null,
        schoolAddress: initialData?.contactAddress?.address ?? '',
        courseName: initialData?.courseName ?? '',
        dateOfEnrolled: initialData?.dateOfEnrolled ?? '',
        dateOfCompleted: initialData?.dateOfCompleted ?? '',
        trainingTypes: initialData?.trainingTypes ?? [],
        proficiencyLevel: initialData?.proficiencyLevel ?? null,
        files: initialData?.files ?? [],
        schoolState: initialData?.contactAddress?.country ?? null,
        phoneCode: initialData?.contactAddress?.countryPhoneCode ?? null,
        schoolPhoneNumber: initialData?.contactAddress?.phoneNumber ?? '',
        schoolEmail: initialData?.contactAddress?.email ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        primaryTrainingType: Yup.object().required(requiredMessage),
        primaryTrainingTypeCategory: Yup.object().required(requiredMessage),
        diveSchool: Yup.string().when(
            ['primaryTrainingType', 'primaryTrainingTypeCategory'],
            {
                is: (primaryTrainingType, primaryTrainingTypeCategory) =>
                    primaryTrainingType?.id !== OTHER_TRAINING &&
                    (!primaryTrainingTypeCategory ||
                        primaryTrainingTypeCategory?.id === COMMERCIAL ||
                        primaryTrainingTypeCategory?.id === OTHER),
                then: () => Yup.object().required(requiredMessage),
                otherwise: () => Yup.object().nullable(),
            }
        ),
        diveSchoolName: Yup.string().when(
            ['primaryTrainingType', 'primaryTrainingTypeCategory'],
            {
                is: (primaryTrainingType) =>
                    primaryTrainingType?.id === OTHER_TRAINING,
                then: () => Yup.string().trim().required(requiredMessage),
                otherwise: () => Yup.string(),
            }
        ),
        schoolAddress: Yup.string().when(
            ['primaryTrainingTypeCategory', 'diveSchool'],
            {
                is: (primaryTrainingTypeCategory, diveSchool) =>
                    primaryTrainingTypeCategory?.id !== MILITARY &&
                    diveSchool?.creator?.id === userId,
                then: () => Yup.string().trim().required(requiredMessage),
                otherwise: () => Yup.string(),
            }
        ),
        country: Yup.object().when('primaryTrainingTypeCategory', {
            is: (primaryTrainingTypeCategory) =>
                primaryTrainingTypeCategory?.id === MILITARY,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        branch: Yup.object().when('primaryTrainingTypeCategory', {
            is: (primaryTrainingTypeCategory) =>
                primaryTrainingTypeCategory?.id === MILITARY,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        courseName: Yup.string().trim(),
        dateOfEnrolled: Yup.date().test(
            'dateOfEnrolled',
            (value, { parent, createError }) => {
                if (
                    isMonthAfter(
                        value,
                        parent.dateOfCompleted,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.dateOfEnrolledMustBeBefore'),
                        path: 'dateOfEnrolled',
                    })
                }
                if (isDateAfter(value, getTodaysDate(), MOMENT_FORMATS.DATE)) {
                    return createError({
                        message: t(
                            'form.error.dateOfEnrolledMustBeBeforeCurrentDate'
                        ),
                        path: 'dateOfEnrolled',
                    })
                }
                return true
            }
        ),
        dateOfCompleted: Yup.date()
            .test('dateOfCompleted', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                } else {
                    return createError({
                        message: t('form.error.completedDateInFuture'),
                        path: 'dateOfCompleted',
                    })
                }
            })
            .test('dateOfCompleted', (value, { parent, createError }) => {
                if (!parent.dateOfEnrolled) {
                    return true
                }

                if (
                    getIsMonthSameOrAfter(
                        value,
                        parent.dateOfEnrolled,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return true
                } else {
                    return createError({
                        message: t('form.error.completedAfterEnrolledDate'),
                        path: 'dateOfCompleted',
                    })
                }
            })
            .required(requiredMessage),
        trainingTypes: Yup.array()
            .min(1, t('form.error.atLeastOneTrainingType'))
            .required(requiredMessage),
        proficiencyLevel: Yup.object().notRequired(),
        files: Yup.array().notRequired(),
        schoolEmail: Yup.string().when('diveSchool', {
            is: (diveSchool) => diveSchool?.creator?.id === userId,
            then: () => validateEmail(t),
            otherwise: () => Yup.string(),
        }),
        phoneCode: Yup.object()
            .nullable()
            .test('schoolPhoneNumber', requiredMessage, function (value) {
                const { schoolPhoneNumber, diveSchool } = this.parent
                return (
                    !(schoolPhoneNumber && !value) ||
                    diveSchool?.creator?.id !== userId
                )
            }),
        schoolPhoneNumber: Yup.string().when('diveSchool', {
            is: (diveSchool) => diveSchool?.creator?.id === userId,
            then: () =>
                Yup.string().when('phoneCode', {
                    is: (phoneCode) => !phoneCode,
                    then: () => validatePhoneNumberNotRequired(t),
                    otherwise: () => validatePhoneNumber(t, requiredMessage),
                }),
            otherwise: () => Yup.string().notRequired(),
        }),
    })

    const handleCloseCertificateForm = () => {
        setShowCertificateForm(false)
        setEditCertificateData(null)
        setEditCertificateIndex(null)
    }

    useEffect(() => {
        const scrollToForm = (formClass) => {
            const form = document.querySelector(formClass)
            if (form) {
                form.scrollIntoView({ behavior: 'smooth' })
            }
        }

        if (editCertificateIndex !== null) {
            scrollToForm('.-certificateForm')
        }
    }, [editCertificateIndex])

    const isProfessionalTraining =
        primaryTrainingType && primaryTrainingType.id === 1

    const submitFormData = async (formData, setSubmitting) => {
        const isSchoolCreator = formData?.diveSchool?.creator?.id === userId

        try {
            setSubmitting(true)
            setSubmitted(true)

            await handleSubmit({
                ...formData,
                trainingCertificates: certificates.map((item) =>
                    formatRequestData(ENTITIES.TRAINING_CERTIFICATE, item)
                ),
                ...(isSchoolCreator && {
                    contactAddress: formatRequestData('contact_addresses', {
                        address: formData.schoolAddress,
                        email: formData.schoolEmail,
                        phoneNumber: formData.schoolPhoneNumber,
                        country: formData.schoolState,
                        countryPhoneCode: formData.phoneCode,
                    }),
                }),
                user: { id: userId, entityType: ENTITIES.USER },
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
            setSubmitted(false)
        }
    }

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        if (showCertificateForm) {
            showConfirmationModal({
                title: `${
                    isProfessionalTraining
                        ? 'general.saveChangesForProfessionalDiverTraining'
                        : 'general.saveChangesForVocationalTraining'
                }`,
                message: `${
                    isProfessionalTraining
                        ? 'message.areYouSureSavingCertificateProfessionalTraining'
                        : 'message.areYouSureSavingCertificateVocationalTraining'
                }`,
                handleConfirm: () => {
                    submitFormData(formData, setSubmitting)
                    closeConfirmationModal()
                },
                handleCancel: () => closeConfirmationModal(),
            })
        } else {
            submitFormData(formData, setSubmitting)
        }
    }

    const handleClose = () => setOpen(false)

    const getModalTitle = () => {
        const isNew = !initialData

        if (isNew && isProfessionalTraining) {
            return t('general.addProfessionalTraining')
        }

        if (isNew && !isProfessionalTraining) {
            return t('general.addVocationalTraining')
        }

        if (isProfessionalTraining) {
            return t('general.editProfessionalTraining')
        }

        return t('general.editVocationalTraining')
    }

    const handleViewQR = (item) => {
        setCertificateQR(item)
    }

    const handleAddToWalletViaEmail = (item) => {
        setSendCertificateToEmail(item)
    }

    return (
        <Fragment>
            <Modal
                open={open}
                setOpen={setOpen}
                closeOnClickOutside={false}
                formInForm
                title={getModalTitle()}
                noTranslateTitle
            >
                <div className="_wr">
                    <div className="-body">
                        <div className="_w -bodyContent">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validation}
                                onSubmit={onSubmit}
                                innerRef={formRef}
                            >
                                {({
                                    values: {
                                        primaryTrainingType,
                                        primaryTrainingTypeCategory,
                                        dateOfEnrolled,
                                        dateOfCompleted,
                                        diveSchool,
                                    },
                                    isSubmitting,
                                }) => {
                                    const showSchoolSelect =
                                        primaryTrainingType?.id !==
                                            OTHER_TRAINING &&
                                        (!primaryTrainingTypeCategory ||
                                            primaryTrainingTypeCategory?.id !==
                                                MILITARY)
                                    const showSchoolInput =
                                        primaryTrainingType?.id ===
                                        OTHER_TRAINING
                                    const showMilitaryFields =
                                        primaryTrainingTypeCategory?.id ===
                                        MILITARY
                                    const showAdditionalSchoolFields = [
                                        OTHER,
                                        COMMERCIAL,
                                    ].includes(primaryTrainingTypeCategory?.id)

                                    const isSchoolCreator =
                                        diveSchool?.creator?.id === userId

                                    return (
                                        <Form>
                                            <FocusError />
                                            <TrainingCourseFormContext
                                                showAdditionalSchoolFields={
                                                    showAdditionalSchoolFields
                                                }
                                            />
                                            <Fragment>
                                                <div className="_12 _m6">
                                                    <SelectField
                                                        name="primaryTrainingTypeCategory"
                                                        entityType={
                                                            ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY
                                                        }
                                                        params={{
                                                            'primaryTrainingType.id':
                                                                primaryTrainingType?.id,
                                                        }}
                                                        reload={
                                                            primaryTrainingType?.id
                                                        }
                                                        required
                                                    />
                                                </div>
                                                <Separator />
                                                {showSchoolSelect && (
                                                    <div className="_12 _m6">
                                                        <SelectField
                                                            name="diveSchool"
                                                            entityType={
                                                                ENTITIES.DIVE_SCHOOL
                                                            }
                                                            params={{
                                                                'primaryTrainingTypeCategory.id':
                                                                    primaryTrainingTypeCategory?.id,
                                                                include:
                                                                    'contactAddress,contactAddress.country,contactAddress.countryPhoneCode',
                                                            }}
                                                            reload={
                                                                primaryTrainingTypeCategory?.id
                                                            }
                                                            required
                                                            searchable
                                                            createNew
                                                            createNewParams={{
                                                                related_entity:
                                                                    ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY,
                                                                related_entity_id:
                                                                    primaryTrainingTypeCategory?.id,
                                                            }}
                                                            disabled={
                                                                !primaryTrainingTypeCategory?.id
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {showSchoolInput && (
                                                    <div className="_12 _m6">
                                                        <InputField
                                                            name="diveSchoolName"
                                                            required
                                                        />
                                                    </div>
                                                )}
                                                <div className="_w">
                                                    {showAdditionalSchoolFields && (
                                                        <Fragment>
                                                            <div className="_12 _m6">
                                                                <InputField
                                                                    name="schoolAddress"
                                                                    required={
                                                                        isSchoolCreator
                                                                    }
                                                                    icon={
                                                                        ICONS.LOCATION
                                                                    }
                                                                    iconColor={
                                                                        COLORS.DARK_BLUE_60
                                                                    }
                                                                    size={
                                                                        ICON_SIZE.SIZE20
                                                                    }
                                                                    disabled={
                                                                        !isSchoolCreator
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="_12 _m6">
                                                                <SelectField
                                                                    name="schoolState"
                                                                    entityType={
                                                                        ENTITIES.COUNTRY
                                                                    }
                                                                    searchable
                                                                    disabled={
                                                                        !isSchoolCreator
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="_12 _m6">
                                                                <InputField
                                                                    name="schoolEmail"
                                                                    type={
                                                                        INPUT_FILED_TYPE.EMAIL
                                                                    }
                                                                    icon={
                                                                        ICONS.MAIL
                                                                    }
                                                                    size={
                                                                        ICON_SIZE.SIZE20
                                                                    }
                                                                    iconColor={
                                                                        COLORS.DARK_BLUE_60
                                                                    }
                                                                    disabled={
                                                                        !isSchoolCreator
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="_12 _m6">
                                                                <div className="a-phoneNumberField aligned-baseline fakeLabel -notFirst">
                                                                    <SelectField
                                                                        name="phoneCode"
                                                                        label="form.label.schoolPhoneNumber"
                                                                        placeholder="form.placeholder.newPhoneNumber"
                                                                        entityType={
                                                                            ENTITIES.COUNTRY
                                                                        }
                                                                        displayAttribute="callingCode"
                                                                        searchAttribute="callingCode"
                                                                        iconAttribute="flag"
                                                                        params={{
                                                                            sort: 'callingCode',
                                                                        }}
                                                                        searchable
                                                                        disabled={
                                                                            !isSchoolCreator
                                                                        }
                                                                    />
                                                                    <InputField
                                                                        name="schoolPhoneNumber"
                                                                        label="general.fakeLabel"
                                                                        placeholder="form.placeholder.phone"
                                                                        icon={
                                                                            ICONS.PHONE
                                                                        }
                                                                        iconColor={
                                                                            COLORS.DARK_BLUE_60
                                                                        }
                                                                        size={
                                                                            ICON_SIZE.SIZE20
                                                                        }
                                                                        disabled={
                                                                            !isSchoolCreator
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )}
                                                </div>
                                                {showMilitaryFields && (
                                                    <Fragment>
                                                        <div className="_12 _m6">
                                                            <SelectField
                                                                name="country"
                                                                entityType={
                                                                    ENTITIES.COUNTRY
                                                                }
                                                                searchable
                                                                required
                                                            />
                                                        </div>
                                                        <div className="_12 _m6">
                                                            <SelectField
                                                                name="branch"
                                                                entityType={
                                                                    ENTITIES.BRANCH
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <Separator />
                                                <div className="_12 _m6">
                                                    <InputField name="courseName" />
                                                </div>
                                                <div className="_w">
                                                    <div className="_12 _m6">
                                                        <DateTimeField
                                                            name="dateOfEnrolled"
                                                            dateFormat="MMM/y"
                                                            monthPicker
                                                            maxDate={
                                                                dateOfCompleted
                                                                    ? new Date(
                                                                          dateOfCompleted
                                                                      )
                                                                    : getTodaysDate()
                                                            }
                                                        />
                                                    </div>
                                                    <div className="_12 _m6 calendarMovedToRight">
                                                        <DateTimeField
                                                            name="dateOfCompleted"
                                                            dateFormat="MMM/y"
                                                            monthPicker
                                                            required
                                                            maxDate={getTodaysDate()}
                                                            minDate={
                                                                new Date(
                                                                    dateOfEnrolled
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="_12 _m6">
                                                        <MultiselectField
                                                            name="trainingTypes"
                                                            entityType={
                                                                ENTITIES.TRAINING_TYPE
                                                            }
                                                            params={
                                                                primaryTrainingType?.id ===
                                                                OTHER_TRAINING
                                                                    ? {
                                                                          'primaryTrainingTypeCategory.id':
                                                                              primaryTrainingTypeCategory?.id,
                                                                      }
                                                                    : {
                                                                          'primaryTrainingType.id':
                                                                              primaryTrainingType?.id,
                                                                      }
                                                            }
                                                            reload={
                                                                primaryTrainingTypeCategory?.id
                                                            }
                                                            required
                                                            searchable
                                                            createNew
                                                            createNewParams={{
                                                                related_entity:
                                                                    primaryTrainingType?.id ===
                                                                    OTHER_TRAINING
                                                                        ? ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY
                                                                        : ENTITIES.PRIMARY_TRAINING_TYPE,
                                                                related_entity_id:
                                                                    primaryTrainingType?.id ===
                                                                    OTHER_TRAINING
                                                                        ? primaryTrainingTypeCategory?.id
                                                                        : primaryTrainingType?.id,
                                                            }}
                                                            disabled={
                                                                !primaryTrainingTypeCategory?.id
                                                            }
                                                        />
                                                    </div>
                                                    <div className="_12 _m6">
                                                        <SelectField
                                                            name="proficiencyLevel"
                                                            entityType={
                                                                ENTITIES.PROFICIENCY_LEVEL
                                                            }
                                                        />
                                                    </div>
                                                    <Separator />
                                                    <FileUploadField
                                                        name="files"
                                                        label="form.label.trainingCourseFiles"
                                                        fileCategory={
                                                            FILE_CATEGORIES.TRAINING_COURSE
                                                        }
                                                    />
                                                    <Separator />
                                                </div>
                                                {certificates.length === 0 && (
                                                    <div className="_w -mt20">
                                                        <div className="_12">
                                                            <span className="a-bodyTextRegular">
                                                                {t(
                                                                    'general.addedCertificates'
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="_12">
                                                            <div className="column fullWidth">
                                                                <span className="a-captionsTextRegular centered-text fullWidth">
                                                                    {t(
                                                                        'general.noItems'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {certificates.length > 0 && (
                                                    <CardContainer noBorderCard>
                                                        <Accordion
                                                            title="general.addedCertificates"
                                                            openByDefault={
                                                                false
                                                            }
                                                            isLoading={
                                                                showCertificateForm
                                                            }
                                                            withBorders
                                                        >
                                                            {certificates.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    const status =
                                                                        item
                                                                            ?.certificateVerification
                                                                            ?.verifiedStatus
                                                                    const isAdci =
                                                                        isADCI(
                                                                            item?.certificateIssuer
                                                                        )
                                                                    const hasQRCode =
                                                                        !!item.certificateHash

                                                                    const googleAvailable =
                                                                        item &&
                                                                        item.googlePassCertificate &&
                                                                        item
                                                                            .googlePassCertificate
                                                                            .status ===
                                                                            WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

                                                                    const appleAvailable =
                                                                        item &&
                                                                        item.applePassCertificate &&
                                                                        item
                                                                            .applePassCertificate
                                                                            .applePassStatus ===
                                                                            WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <CardHeader
                                                                                title={
                                                                                    item.name
                                                                                }
                                                                                item={
                                                                                    item
                                                                                }
                                                                                hideShowRecord={checkIfExpired(
                                                                                    item.expiresDate
                                                                                )}
                                                                                statuses={
                                                                                    isAdci
                                                                                        ? [
                                                                                              getCertificateStatus(
                                                                                                  status,
                                                                                                  t
                                                                                              ),
                                                                                          ]
                                                                                        : []
                                                                                }
                                                                                actions={[
                                                                                    canEditCertificate(
                                                                                        isAdci,
                                                                                        status
                                                                                    ) && {
                                                                                        handleAction:
                                                                                            () =>
                                                                                                handleEdit(
                                                                                                    item,
                                                                                                    index
                                                                                                ),
                                                                                        icon: ICONS.EDIT,
                                                                                        iconColor:
                                                                                            COLORS.DARK_BLUE,
                                                                                        label: 'button.editTrainingCertificate',
                                                                                    },
                                                                                    canViewQRCode(
                                                                                        isAdci,
                                                                                        status,
                                                                                        hasQRCode
                                                                                    ) && {
                                                                                        handleAction:
                                                                                            handleViewQR,
                                                                                        icon: ICONS.QR,
                                                                                        iconColor:
                                                                                            COLORS.DARK_BLUE,
                                                                                        label: 'button.viewQrCode',
                                                                                    },
                                                                                    (googleAvailable ||
                                                                                        appleAvailable) && {
                                                                                        handleAction:
                                                                                            handleAddToWalletViaEmail,
                                                                                        icon: ICONS.MAIL,
                                                                                        iconColor:
                                                                                            COLORS.DARK_BLUE,
                                                                                        label: 'button.sendCertificateToYourDevice',
                                                                                    },
                                                                                    canDeleteCertificate(
                                                                                        isAdci,
                                                                                        status
                                                                                    ) && {
                                                                                        handleAction:
                                                                                            () =>
                                                                                                handleDelete(
                                                                                                    index
                                                                                                ),
                                                                                        icon: ICONS.DELETE,
                                                                                        iconColor:
                                                                                            COLORS.RED,
                                                                                        label: 'button.deleteTrainingCertificate',
                                                                                        title: 'general.deleteTrainingCertificate',
                                                                                        confirmationMessage:
                                                                                            'message.areYouSureDeleteTrainingCertificate',
                                                                                        textColor:
                                                                                            '-redText',
                                                                                    },
                                                                                ].filter(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        !!item
                                                                                )}
                                                                            />
                                                                            <CertificateCard
                                                                                item={
                                                                                    item
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            )}
                                                        </Accordion>
                                                    </CardContainer>
                                                )}
                                            </Fragment>

                                            {!showCertificateForm && (
                                                <div className="_w -mt30 -pb20">
                                                    <div className="_12 _m4">
                                                        <Button
                                                            btnClass={
                                                                BUTTON_STATUS.SECONDARY
                                                            }
                                                            type={
                                                                BUTTON_TYPE.BUTTON
                                                            }
                                                            label="button.addCertificate"
                                                            icon={ICONS.PLUS}
                                                            iconColor={
                                                                COLORS.LIGHT_BLUE
                                                            }
                                                            onClick={() =>
                                                                setShowCertificateForm(
                                                                    true
                                                                )
                                                            }
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {isSubmitting && <Loader />}
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {showCertificateForm && (
                                <div className="-pb20">
                                    <CertificateForm
                                        modal={false}
                                        title={'general.certificates'}
                                        setShowCertificateForm={
                                            handleCloseCertificateForm
                                        }
                                        setCertificates={setCertificates}
                                        initialData={editCertificateData}
                                        editCertificateIndex={
                                            editCertificateIndex
                                        }
                                        setEditCertificateIndex={
                                            setEditCertificateIndex
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <ModalButtons
                    isSubmitting={submitted}
                    setOpen={setOpen}
                    buttons={{
                        nextBtn: {
                            handleClick: () => formRef?.current?.handleSubmit(),
                        },
                    }}
                />
            </Modal>
            {!!certificateQR && (
                <CertificateQrCode
                    certificate={certificateQR}
                    setCertificate={setCertificateQR}
                />
            )}
            {!!sendCertificateToEmail && (
                <AddCertificateToWalletViaEmail
                    certificate={sendCertificateToEmail}
                    setCertificate={setSendCertificateToEmail}
                />
            )}
        </Fragment>
    )
}

TrainingCourseForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
}

export default TrainingCourseForm
