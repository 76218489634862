import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    ICON_SIZE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import { createEntityService, editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import CardFooter from 'components/card/CardFooter'

const DiveTeamListItemFeedback = ({
    diveTeamItem,
    isProjectClient,
    refetchData,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    if (!diveTeamItem || !isProjectClient) {
        return null
    }

    const currentUserFeedback = diveTeamItem.feedbacks.find(item => item.userId === userId);

    const isAlreadyApproved = currentUserFeedback && currentUserFeedback.isPositive === true;
    const isAlreadyDisapproved = currentUserFeedback && currentUserFeedback.isPositive === false;

    const handleFeedback = async (feedbackValue) => {
        try {
            if (currentUserFeedback) {
                await editEntityService(
                    ENTITIES.DIVER_INVITE_FEEDBACK,
                    currentUserFeedback.feedbackId,
                    {
                        approved: feedbackValue,
                    }
                )
            } else {
                await createEntityService(ENTITIES.DIVER_INVITE_FEEDBACK, {
                    diverInvite: {
                        id: diveTeamItem.id,
                        entityType: ENTITIES.INVITE,
                    },
                    reviewer: {
                        id: userId,
                        entityType: ENTITIES.USER,
                    },
                    approved: feedbackValue,
                })
            }

            setAlert(t('general.feedbackSaved'), ALERT_TYPES.SUCCESS)
            refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <CardFooter withBorder>
            <Button
                btnClass={isAlreadyDisapproved ? BUTTON_STATUS.TERTIARY : BUTTON_STATUS.SECONDARY}
                buttonSize={BUTTON_SIZE.MEDIUM}
                label={isAlreadyDisapproved ? 'button.disapprovedByYou' : "button.disapprove"}
                onClick={() => handleFeedback(isAlreadyDisapproved ? null : false)}
                icon={ICONS.DISLIKE}
                iconSize={ICON_SIZE.SIZE20}
                iconColor={isAlreadyDisapproved ? COLORS.RED : COLORS.LIGHT_BLUE}
                iconPosition={ICON_POSITION.RIGHT}
            />

            <Button
                btnClass={isAlreadyApproved ? BUTTON_STATUS.TERTIARY : BUTTON_STATUS.SECONDARY}
                buttonSize={BUTTON_SIZE.MEDIUM}
                label={isAlreadyApproved ? 'button.endorsedByYou' : 'button.endorse'}
                onClick={() => handleFeedback(isAlreadyApproved ? null : true)}
                icon={ICONS.LIKE}
                iconSize={ICON_SIZE.SIZE20}
                iconColor={isAlreadyApproved ? COLORS.GREEN : COLORS.LIGHT_BLUE}
                iconPosition={ICON_POSITION.RIGHT}
            />
        </CardFooter>
    )
}

DiveTeamListItemFeedback.propTypes = {
    diveTeamItem: PropTypes.object,
    isProjectClient: PropTypes.bool,
    refetchData: PropTypes.func,
}

export default DiveTeamListItemFeedback
