import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

const SimpleNote = ({ note, children, translate = true }) => {
    const t = useTranslate()

    return (
        <p className="a-mediumText a-centeredText fullWidth a-lightText -opacity-60 a-italicText">
            *{children || (translate ? t(note) : note)}
        </p>
    )
}

SimpleNote.propTypes = {
    note: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    translate: PropTypes.bool,
}

export default SimpleNote
