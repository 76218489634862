import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import useWindowDimensions from 'hooks/useWindowDimension'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ProfileContext } from 'screens/diver/profile/context/ProfileContext'

import { editEntityService } from 'services/entity.service'
import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'

import { positionCategoryIds } from 'utils/positionHelper'
import { validateEmail } from 'utils/yupValidations'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    DIVER_POSITION_CATEGORIES,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    CheckboxField,
    MultiselectField,
    ProfilePictureUploadField,
    TextAreaField,
} from 'components/formFields'
import DateTimeField from 'components/formFields/DateTimeField'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import SelectGroupField from 'components/formFields/SelectGroupField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Note from 'components/Note'
import CustomIconButtonItem from 'components/customMultiSelectComponents/CustomIconButtonItem'

const PersonalInfoModalContext = ({ setAvailability, setPrimaryPositions }) => {
    const formikContext = useFormikContext()

    const { values } = formikContext

    const { userStatus } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!firstRender.current) {
            setAvailability(userStatus?.code)
        }
    }, [userStatus])

    //Note: This useEffect is used to filter the primary positions that are not in the selected positions.
    // When a user unselects a position, the corresponding primary position will be removed as well.
    useEffect(() => {
        const positionList = [
            ...values.occupationalDivingPersonnel,
            ...values.marineMobileOffshoreUnitPersonnel,
            ...values.rovPersonnel,
            ...values.offshoreSurveyPersonnel,
            ...values.projectMissionPersonnel,
            ...values.projectOperationsManagementPersonnel,
        ]

        setPrimaryPositions((prevPrimaryPositions) =>
            prevPrimaryPositions.filter((primaryPosition) =>
                positionList.some(
                    (position) => position.id === primaryPosition.id
                )
            )
        )
    }, [
        values.occupationalDivingPersonnel,
        values.marineMobileOffshoreUnitPersonnel,
        values.rovPersonnel,
        values.offshoreSurveyPersonnel,
        values.projectMissionPersonnel,
        values.projectOperationsManagementPersonnel,
    ])
}

const PersonalInfoModal = ({ setOpen, open }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { profileHash } = useParams()
    const { isTablet } = useWindowDimensions()

    const { currentUser, userId, fetchCurrentUser, setCurrentUser } =
        useContext(CurrentUserContext)
    const { profile, fetchProfile } = useContext(ProfileContext)
    const { diver } = profile

    const [availability, setAvailability] = useState(profile?.userStatusCode)
    const [errorMessage, setErrorMessage] = useState(false)
    const [primaryPositions, setPrimaryPositions] = useState(
        currentUser?.primaryPositions || []
    )

    const { setAlert } = useContext(AlertContext)

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const initialPositionValues = Object.fromEntries(
        Object.entries(positionCategoryIds).map(([key, id]) => [
            key,
            currentUser.diverPositions?.filter(
                (item) =>
                    item.relationships?.diverPositionCategory?.data?.id === id
            ) ?? [],
        ])
    )

    const commonInitialValues = {
        firstName: currentUser.firstName ?? '',
        middleName: currentUser.middleName ?? '',
        lastName: currentUser.lastName ?? '',
        currentTitle: currentUser.currentTitle ?? '',
        dateOfBirth: currentUser.dateOfBirth ?? '',
        chatHidden: currentUser.chatHidden ?? false,
        country: currentUser.country ?? null,
        nationality: currentUser.nationality ?? '',
        diverLanguages: currentUser.diverLanguages ?? [],
        email: currentUser.email ?? '',
        countryPhoneCode: currentUser.countryPhoneCode ?? null,
        phoneNumber: currentUser.phoneNumber ?? '',
        about: currentUser.about ?? '',
        avatar: currentUser.avatar ?? null,
        occupationalDivingPersonnel:
            initialPositionValues.occupationalDivingPersonnel ?? [],
        marineMobileOffshoreUnitPersonnel:
            initialPositionValues.marineMobileOffshoreUnitPersonnel ?? [],
        rovPersonnel: initialPositionValues.rovPersonnel ?? [],
        offshoreSurveyPersonnel:
            initialPositionValues.offshoreSurveyPersonnel ?? [],
        projectMissionPersonnel:
            initialPositionValues.projectMissionPersonnel ?? [],
        projectOperationsManagementPersonnel:
            initialPositionValues.projectOperationsManagementPersonnel ?? [],
    }

    const diverInitialValues = {
        hidden: currentUser.hidden ?? false,
        userStatus: currentUser.userStatus ?? '',
        regions: currentUser.regions ?? [],
        professionalSummary: currentUser.professionalSummary ?? '',
        isn: currentUser.isn ?? '',
        deVinciCode: currentUser.deVinciCode ?? '',
        vantage: currentUser.vantage ?? '',
    }

    const initialValues = diver
        ? { ...commonInitialValues, ...diverInitialValues }
        : commonInitialValues

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t('form.error.maximumAllowed')} 500 ${t(
        'form.error.characters'
    )}`

    const birthdayValidation = Yup.date().test(
        'dateOfBirth',
        (value, { parent, createError }) => {
            if (getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)) {
                return true
            }
            return createError({
                message: t('form.error.birthDate'),
                path: 'dateOfBirth',
            })
        }
    )

    const validation = Yup.object({
        firstName: Yup.string().trim().required(requiredMessage),
        middleName: Yup.string().trim(),
        lastName: Yup.string().trim().required(requiredMessage),
        dateOfBirth: diver
            ? birthdayValidation.required(requiredMessage)
            : birthdayValidation.notRequired(),
        country: diver
            ? Yup.object().required(requiredMessage)
            : Yup.object().notRequired(),
        nationality: Yup.string().trim(),
        positions: Yup.object()
            .shape({
                occupationalDivingPersonnel: Yup.array().notRequired(),
                marineMobileOffshoreUnitPersonnel: Yup.array().notRequired(),
                rovPersonnel: Yup.array().notRequired(),
                offshoreSurveyPersonnel: Yup.array().notRequired(),
                projectMissionPersonnel: Yup.array().notRequired(),
                projectOperationsManagementPersonnel: Yup.array().notRequired(),
            })
            .test('positions', (value, { parent }) => {
                if (
                    parent.occupationalDivingPersonnel.length === 0 &&
                    parent.marineMobileOffshoreUnitPersonnel.length === 0 &&
                    parent.rovPersonnel.length === 0 &&
                    parent.offshoreSurveyPersonnel.length === 0 &&
                    parent.projectMissionPersonnel.length === 0 &&
                    parent.projectOperationsManagementPersonnel.length === 0
                ) {
                    return setErrorMessage(true)
                }
                setErrorMessage(false)
                return true
            }),
        diverLanguages: diver
            ? Yup.array()
                  .min(1, t('form.error.atLeastOneLanguage'))
                  .required(requiredMessage)
            : Yup.array().notRequired(),
        email: validateEmail(t).required(requiredMessage),
        regions: diver
            ? Yup.array()
                  .min(1, t('form.error.addAtLeastOne'))
                  .required(requiredMessage)
            : Yup.array().notRequired(),
        userStatus: diver
            ? Yup.object().required(requiredMessage)
            : Yup.object().nullable(),
        about: Yup.string().max(500, maximumCharacters),
    })

    const handlePrimaryPosition = (position) => {
        const isPositionExist = primaryPositions.some(
            (item) => item.id === position.id
        )

        if (isPositionExist) {
            setPrimaryPositions((state) =>
                state.filter((item) => item.id !== position.id)
            )
        } else {
            setPrimaryPositions((state) => [...state, position])

            if (primaryPositions.length > 2) {
                setAlert(
                    t('message.maximumPrimaryPositions'),
                    ALERT_TYPES.WARNING
                )
            }
        }
    }

    const handleSubmit = async (
        {
            diverLanguages,
            diverPositions,
            marineMobileOffshoreUnitPersonnel,
            offshoreSurveyPersonnel,
            occupationalDivingPersonnel,
            rovPersonnel,
            projectMissionPersonnel,
            projectOperationsManagementPersonnel,
            ...formData
        },
        { setSubmitting }
    ) => {
        const positionList = [
            marineMobileOffshoreUnitPersonnel,
            offshoreSurveyPersonnel,
            occupationalDivingPersonnel,
            rovPersonnel,
            projectMissionPersonnel,
            projectOperationsManagementPersonnel,
        ]
        const diverPositionList = Object.values(positionList)
            .flat()
            .map((role) => role)

        try {
            if (primaryPositions?.length > 3) {
                setAlert(
                    t('message.maximumPrimaryPositions'),
                    ALERT_TYPES.WARNING
                )
            } else {
                setSubmitting(true)

                const response = await editEntityService(
                    ENTITIES.USER,
                    userId,
                    {
                        ...formData,
                        diverPositions: diverPositionList,
                        primaryPositions: primaryPositions,
                        diverLanguages: diverLanguages.map((item) => ({
                            ...item,
                            user: {
                                id: userId,
                                entityType: ENTITIES.USER,
                            },
                        })),
                    },
                    true,
                    ['diverLanguages']
                )
                fetchCurrentUser()
                if (profileHash === response.data.profileHash) {
                    fetchProfile({ profileHash: response.data.profileHash })
                } else {
                    setCurrentUser((state) => ({
                        ...state,
                        profileHash: response.data.profileHash,
                    }))
                    navigate(
                        `${ROUTES.DIVER_PROFILE}/${response.data.profileHash}`
                    )
                }
                setOpen(false)
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const renderCustomItem = (item) => {
        const isActive = primaryPositions.some(
            (position) => position.id === item.id
        )
        return (
            <CustomIconButtonItem
                item={item.name}
                isActive={isActive}
                tooltip="general.markAsPrimaryRole"
                iconActive={ICONS.STAR_FILL}
                iconNotActive={ICONS.STAR}
                handleClick={() => handlePrimaryPosition(item)}
            />
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form className="_wr personalInfoModal">
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.editPersonalInformation"
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.savePersonalInformation',
                                handleClick: handleSubmit,
                            },
                        }}
                    >
                        <PersonalInfoModalContext
                            setAvailability={setAvailability}
                            setPrimaryPositions={setPrimaryPositions}
                        />
                        <div className="_wr -contentElements">
                            <div className="_w justify-center  -mb15">
                                <ProfilePictureUploadField
                                    name="avatar"
                                    profilePage={true}
                                    availability={availability}
                                    fileCategory={FILE_CATEGORIES.USER_AVATAR}
                                />
                                {diver && (
                                    <div className="_12 _m9 -mainInfo">
                                        <div className="_12 _m6">
                                            <SelectField
                                                name="userStatus"
                                                entityType={
                                                    ENTITIES.USER_STATUSES
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.OBJECT
                                                }
                                                required
                                            />
                                        </div>
                                        <div
                                            className={`_12 _m6 centered-checkbox ${
                                                isTablet
                                                    ? 'column -gap20'
                                                    : 'space-between'
                                            }`}
                                        >
                                            <CheckboxField
                                                name="hidden"
                                                label="form.label.hidden"
                                                title={
                                                    'general.profileVisibility'
                                                }
                                                tooltip={
                                                    'form.label.hiddenUserText'
                                                }
                                                icon={ICONS.HELP_AND_INFO}
                                                translate
                                            />
                                            <div className="-hideChatField">
                                                <CheckboxField
                                                    name="chatHidden"
                                                    label="form.label.hidden"
                                                    title={
                                                        'general.chatVisibility'
                                                    }
                                                    tooltip={
                                                        'form.label.chatHiddenUserText'
                                                    }
                                                    icon={ICONS.HELP_AND_INFO}
                                                    translate
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <InputField name="firstName" required />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="middleName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="lastName" required />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="currentTitle"
                                        label="form.label.title"
                                        placeholder="form.placeholder.title"
                                    />
                                </div>
                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="dateOfBirth"
                                        icon={true}
                                        required={!!diver}
                                        maxDate={getTodaysDate()}
                                    />
                                </div>
                                {!diver && (
                                    <div className="_12 _m6 centered-checkbox">
                                        <CheckboxField
                                            name="chatHidden"
                                            label="form.label.hidden"
                                            title={'general.chatVisibility'}
                                            tooltip={
                                                'form.label.chatHiddenUserText'
                                            }
                                            icon={ICONS.HELP_AND_INFO}
                                            translate
                                        />
                                    </div>
                                )}
                            </div>
                            {diver && (
                                <div className="_w">
                                    <Separator />
                                    <div className="_12 _m6">
                                        <InputField name="isn" />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField name="deVinciCode" />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField name="vantage" />
                                    </div>
                                </div>
                            )}
                            <div className="_w -selectRolesFields">
                                <Separator />
                                <div className="_12">
                                    <div className="column -gap5">
                                        <span className="a-bodyTextMedium">
                                            {t('general.selectYourRoles')}
                                        </span>
                                        <span className="a-mediumText a-lightText">
                                            {t(
                                                'general.selectYourMostRecentPosition'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="occupationalDivingPersonnel"
                                        label="form.label.occupationalDivingPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .DIVING_PERSONNEL.id,
                                        }}
                                        searchable
                                        tooltip="form.label.occupationalDivingPersonnelTooltip"
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="marineMobileOffshoreUnitPersonnel"
                                        label="form.label.marineMobileOffshoreUnitPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .MARINE_MOU_PERSONNEL.id,
                                        }}
                                        searchable
                                        tooltip="form.label.marineMobileOffshoreUnitPersonnelTooltip"
                                        tooltipPosition={TOOLTIP_POSITION.LEFT}
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="rovPersonnel"
                                        label="form.label.rovPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .ROV_PERSONNEL.id,
                                        }}
                                        searchable
                                        tooltip="form.label.rovPersonnelTooltip"
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="offshoreSurveyPersonnel"
                                        label="form.label.offshoreSurveyPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .SURVEY_PERSONNEL.id,
                                        }}
                                        searchable
                                        tooltip="form.label.offshoreSurveyPersonnelTooltip"
                                        tooltipPosition={TOOLTIP_POSITION.LEFT}
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="projectMissionPersonnel"
                                        label="form.label.projectMissionPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .PROJECT_MISSION_PERSONNEL
                                                    .id,
                                        }}
                                        searchable
                                        tooltip="form.label.projectMissionPersonnelTooltip"
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                <div className="_12 _m6 -mt20">
                                    <MultiselectField
                                        name="projectOperationsManagementPersonnel"
                                        label="form.label.projectOperationsManagementPersonnel"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        params={{
                                            'diverPositionCategory.id':
                                                DIVER_POSITION_CATEGORIES
                                                    .PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
                                                    .id,
                                        }}
                                        searchable
                                        tooltip="form.label.projectOperationsManagementPersonnelTooltip"
                                        tooltipPosition={TOOLTIP_POSITION.LEFT}
                                        renderCustomItem={renderCustomItem}
                                        customPillClassName="-withIconButton"
                                    />
                                </div>
                                {errorMessage && (
                                    <div className="_12">
                                        <span className="errorMessage  -active">
                                            {t(
                                                'form.error.selectAtLeastOneRole'
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12 _m6">
                                    <SelectField
                                        name="country"
                                        label="form.label.countryOfResidence"
                                        placeholder="form.placeholder.countryOfResidence"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required={!!diver}
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="nationality" />
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12 a-oneLineSelectGroup">
                                    <SelectGroupField
                                        name="diverLanguages"
                                        title="general.addLanguages"
                                        description="general.pleaseAddAllLanguages"
                                        fieldsName={[
                                            'language',
                                            'languageProficiency',
                                        ]}
                                        fieldsPlaceholder={[
                                            'form.placeholder.selectLanguage',
                                            'form.placeholder.selectProficiency',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.LANGUAGE,
                                            ENTITIES.LANGUAGE_PROFICIENCY,
                                        ]}
                                        fieldsRequired={[true, true]}
                                        fieldsShowLabel={[false, false]}
                                        required={!!diver}
                                        fieldsSearchable={[true, true]}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <Note note="notes.changeEmailAndPhoneNumber" />
                                </div>
                                <div className="_12 _l6">
                                    <InputField
                                        name="email"
                                        label="form.label.profileEmail"
                                        placeholder="form.placeholder.profileEmail"
                                        type={INPUT_FILED_TYPE.EMAIL}
                                        icon={ICONS.MAIL}
                                        size={ICON_SIZE.SIZE20}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <div className="a-phoneNumberField aligned-baseline fakeLabel -notFirst">
                                        <SelectField
                                            name="countryPhoneCode"
                                            label="form.label.phone"
                                            entityType={ENTITIES.COUNTRY}
                                            displayAttribute="callingCode"
                                            searchAttribute="callingCode"
                                            iconAttribute="flag"
                                            showPlaceholder={false}
                                            params={{ sort: 'callingCode' }}
                                            searchable
                                            disabled
                                        />
                                        <InputField
                                            name="phoneNumber"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.phone"
                                            icon={ICONS.PHONE}
                                            iconColor={COLORS.DARK_BLUE_60}
                                            size={ICON_SIZE.SIZE20}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="about"
                                        label="form.label.aboutMe"
                                        placeholder="form.placeholder.aboutMe"
                                        largeTextarea
                                    />
                                </div>
                            </div>
                            {diver && (
                                <div className="_w">
                                    <Separator />
                                    <div className="_12">
                                        <TextAreaField
                                            name="professionalSummary"
                                            label="form.label.professionalSummary"
                                            placeholder="form.placeholder.professionalSummary"
                                            largeTextarea
                                        />
                                    </div>
                                </div>
                            )}
                            {diver && (
                                <div className="_w">
                                    <Separator />
                                    <div className="_12 _m6">
                                        <MultiselectField
                                            name="regions"
                                            label="form.label.regions"
                                            placeholder="form.placeholder.regions"
                                            entityType={ENTITIES.REGIONS}
                                            searchable
                                            required
                                            dropup
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

PersonalInfoModal.propTypes = {
    setOpen: PropTypes.func,
    open: PropTypes.bool,
}

export default PersonalInfoModal
