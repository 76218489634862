import PropTypes from 'prop-types'

import { getValueByKey } from 'utils/dataFormatters'

import SingleStatus from './SingleStatus'

const ListOfStatues = ({
    statuses,
    statusColor = '-gray',
    centered = false,
    attributeKey,
    customStatusValue,
}) => {
    //Note: If customStatusValue is defined, it will be used to generate a custom formatted string based on the item.
    // This is useful when you need to customize how the status is displayed, such as combining multiple attributes.

    //     Example usage:
    //  customStatusValue={(item) =>
    //      `${getValueByKey('language.data.attributes.name', item)} - ${getValueByKey('languageProficiency.data.attributes.name', item)}`
    //  }
    const renderStatusItem = (attributeKey, item) => {
        if (customStatusValue) return customStatusValue(item)
        return attributeKey ? getValueByKey(attributeKey, item) : item
    }

    return (
        <div
            className={`listOfStatuses ${centered ? 'justify-center' : 'justify-start'} aligned-center`}
        >
            {statuses.map((item, index) => {
                return (
                    <SingleStatus
                        statusColor={statusColor}
                        item={renderStatusItem(attributeKey, item)}
                        key={index}
                    />
                )
            })}
        </div>
    )
}

ListOfStatues.propTypes = {
    statuses: PropTypes.array,
    statusColor: PropTypes.string,
    centered: PropTypes.bool,
    attributeKey: PropTypes.string,
    customStatusValue: PropTypes.func,
}

export default ListOfStatues
