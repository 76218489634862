import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { createEntityService } from 'services/entity.service'

import { ALERT_TYPES, DIVE_RECORD_TYPE, ICON_SIZE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Modal from 'components/Modal'
import { SelectField } from 'components/formFields'
import Loader from 'components/Loader'

const SecondLevelOfVerification = ({
    verificationLevel,
    diveRecord,
    fetchData,
    setOpen,
    handleClose,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const isSupervisorDiveRecord =
        diveRecord.diveRecordType === DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id

    const initialValues = {
        targetSupervisor: null,
        targetCompany: null,
    }

    const validation = Yup.object({
        targetSupervisor: isSupervisorDiveRecord
            ? Yup.object().nullable()
            : Yup.object().required(t('form.error.required')),
        targetCompany: isSupervisorDiveRecord
            ? Yup.object().required(t('form.error.required'))
            : Yup.object().nullable(),
    })

    const handleSubmit = async (
        { targetSupervisor, targetCompany },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)

            const targetData = isSupervisorDiveRecord
                ? {
                      targetCompany: {
                          id: targetCompany.id,
                          entityType: ENTITIES.COMPANY,
                      },
                  }
                : {
                      targetSupervisor: {
                          id: targetSupervisor.id,
                          entityType: ENTITIES.USER,
                      },
                  }

            await createEntityService(
                ENTITIES.DIVE_RECORD_SECOND_LEVEL_VERIFICATION,
                {
                    diveRecord: {
                        id: diveRecord.id,
                        entityType: ENTITIES.DIVE_RECORD,
                    },
                    ...targetData,
                }
            )
            fetchData()
            showConfirmationModal({
                title: 'general.success',
                message: 'message.diveRecordSubmittedForVerification',
                icon: ICONS.CHECKMARK_CIRCLE,
                iconColor: COLORS.GREEN_60,
                iconSize: ICON_SIZE.SIZE80,
                confirmLabel: 'button.okClose',
                handleConfirm: closeConfirmationModal,
                hideCancel: true,
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="-modalElements">
                    <Modal
                        closeOnClickOutside={false}
                        smallModalWithDropdowns={true}
                        title={verificationLevel.title}
                        setOpen={handleClose}
                        isSubmitting={isSubmitting}
                        noTranslateTitle
                        smallModal
                        buttons={{
                            prevBtn: {
                                label: 'button.back',
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.send',
                            },
                        }}
                    >
                        <div className="_12">
                            <p className="a-bodyTextRegular centered-text -mb20">
                                {t('general.findYourSupervisor')}
                            </p>
                        </div>
                        <div className="_12">
                            {isSupervisorDiveRecord ? (
                                <SelectField
                                    name="targetCompany"
                                    label="form.label.company"
                                    placeholder="form.placeholder.searchForCompany"
                                    entityType={ENTITIES.VERIFIED_COMPANIES}
                                    displayAttribute="name"
                                    searchAttribute="name"
                                    searchable
                                    required
                                />
                            ) : (
                                <SelectField
                                    name="targetSupervisor"
                                    label="form.label.supervisorShort"
                                    placeholder="form.placeholder.searchForSupervisor"
                                    entityType={ENTITIES.VERIFIED_DIVERS}
                                    params={{
                                        pro: true,
                                    }}
                                    displayAttribute="fullName"
                                    searchAttribute="fullName"
                                    searchable
                                    required
                                />
                            )}
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default SecondLevelOfVerification
