import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router'

import {
    createEntityService,
    deleteEntityService,
    editEntityService,
} from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Loader from 'components/Loader'
import DiverPositionCard from './DiverPositionCard'
import NoData from 'components/NoData'
import Pagination from 'components/table/Pagination'
import DataAccessShareModal from './DataAccessShareModal'
import Button from '../../../../components/Button'

const DiverPositionList = ({ data, fetchDiveTeam, project, topPosition }) => {
    const t = useTranslate()
    const { currentUser } = useContext(CurrentUserContext)
    const [unavailableProject, setUnavailableProject] = useState(false)
    const [diverPosition, setDiverPosition] = useState({})
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const navigate = useNavigate()
    const { setAlert } = useContext(AlertContext)

    const [dataAccessModalData, setDataAccessModalData] = useState(null)

    const openDataAccessModal = (position, message) => {
        setDataAccessModalData({
            position,
            message,
            closeDataAccessModalData: () => {
                setDataAccessModalData(null)
                fetchData()
                fetchDiveTeam()
            },
        })
    }

    useEffect(() => {
        if (unavailableProject) {
            setAlert(
                { message: getUnavailableProjectMessage() },
                ALERT_TYPES.SUCCESS
            )
            navigate(`${ROUTES.HOME}`)
        }
    }, [unavailableProject])

    const { data: diverProjectPositions, isLoading, fetchData, meta } = data

    const getUnavailableProjectMessage = () => {
        return (
            t('general.successfullyWithdrawn') +
            ' ' +
            diverPosition?.diverPositionName +
            ' ' +
            t('general.onTheProject') +
            ' ' +
            diverPosition.projectName
        )
    }

    const closeConfirmationModalAndRefreshPositions = () => {
        closeConfirmationModal()
        fetchData()
        fetchDiveTeam()
    }

    const handleConfirmationModal = (position, message) => {
        showConfirmationModal({
            title: 'general.success',
            customText: (
                <span className="-mt20 a-bodyTextRegular">
                    {`${t(message)} ${position.diverPositionName} ${t(
                        'general.onTheProject'
                    )}`}{' '}
                    <span className="a-bodyTextBold">
                        {position.projectName}
                    </span>
                </span>
            ),
            confirmLabel: 'button.ok',
            handleConfirm: () => closeConfirmationModalAndRefreshPositions(),
            handleCancel: () => closeConfirmationModalAndRefreshPositions(),
            translateMessage: false,
            hideCancel: true,
        })
    }

    const handleApply = async (position) => {
        try {
            if (position.inviteId) {
                await editEntityService(ENTITIES.INVITE, position.inviteId, {
                    submittedByDiver: true,
                    inviteRejected: false,
                })
            } else {
                await createEntityService(ENTITIES.INVITE, {
                    diverEmail: currentUser.email,
                    submittedByDiver: true,
                    projectPosition: {
                        ...position,
                        entityType: ENTITIES.PROJECT_POSITIONS,
                    },
                })
            }

            openDataAccessModal(position, 'general.successfullyAppliedForThe')
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleReapply = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                personReapplied: true,
            })

            openDataAccessModal(position, 'general.successfullyReappliedForThe')
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleWithdraw = async (position) => {
        try {
            await deleteEntityService(ENTITIES.INVITE, position.inviteId)
            if (project.hidden) {
                setDiverPosition(position)
                setUnavailableProject(true)
                return
            }
            handleConfirmationModal(position, 'general.successfullyWithdrawn')
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleAccept = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                inviteAccepted: true,
            })

            openDataAccessModal(
                position,
                'general.successfullyAcceptedInvitation'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleReject = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                inviteRejected: true,
            })
            handleConfirmationModal(
                position,
                'general.successfullyRejectedInvitation'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (isLoading) return <Loader />

    return (
        <div>
            {dataAccessModalData && (
                <DataAccessShareModal
                    dataAccessModalData={dataAccessModalData}
                />
            )}

            {diverProjectPositions?.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noPositions')}
                    description={t('general.noPositionResultsDesc')}
                />
            )}

            {diverProjectPositions.map((position, index) => {
                return (
                    <div key={position.id + position.diverPositionName + index}>
                        <DiverPositionCard
                            data={position}
                            buttonActions={position.availableActions}
                            handleAccept={() => handleAccept(position)}
                            handleApply={() => handleApply(position)}
                            handleReapply={() => handleReapply(position)}
                            handleReject={() => handleReject(position)}
                            handleWithdraw={() => handleWithdraw(position)}
                        />
                    </div>
                )
            })}
            <Pagination
                totalItems={meta?.totalItems}
                meta={meta}
                scrollToTop
                topPosition={topPosition}
            />
        </div>
    )
}

DiverPositionList.propTypes = {
    data: PropTypes.object,
    fetchDiveTeam: PropTypes.func,
    project: PropTypes.object,
    topPosition: PropTypes.number,
}

DiverPositionList.defaultProps = {
    topPosition: 0,
}

export default DiverPositionList
