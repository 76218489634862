import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import IconButton from './IconButton'
import Icon from './Icon'
import COLORS from 'constants/colors'

const PillItem = ({
    customPillClassName,
    renderCustomValue,
    handleRemove,
    icon,
}) => {
    return (
        <div className={`m-pillItem__item ${customPillClassName || ''}`}>
            {icon && (
                <Icon
                    name={icon}
                    size={ICON_SIZE.SIZE16}
                    iconColor={COLORS.DARK_BLUE_60}
                />
            )}
            {renderCustomValue()}
            <IconButton
                icon={ICONS.CLEAR}
                onClick={handleRemove}
                iconSize={ICON_SIZE.SIZE16}
            />
        </div>
    )
}

export default PillItem
