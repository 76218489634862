import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import IconButton from 'components/IconButton'

const CustomIconButtonItem = ({
    isActive,
    handleClick,
    iconActive,
    iconNotActive,
    tooltip,
    item,
}) => {
    return (
        <div className="aligned-center -gap5">
            <IconButton
                icon={isActive ? iconActive : iconNotActive}
                iconSize={ICON_SIZE.SIZE16}
                iconColor={COLORS.LIGHT_BLUE}
                onClick={() => handleClick()}
                tooltip={tooltip}
            />
            <span className="a-mediumText label">{item}</span>
        </div>
    )
}

CustomIconButtonItem.propTypes = {
    isActive: PropTypes.bool,
    handleClick: PropTypes.func,
    iconActive: PropTypes.string,
    iconNotActive: PropTypes.string,
    tooltip: PropTypes.string,
    item: PropTypes.string,
}

export default CustomIconButtonItem
