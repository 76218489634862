import { Form, Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    isDateBefore,
} from 'services/moment.service'

import useFetchData from 'hooks/useFetchData'

import { formatRequestData } from 'utils/jsonApiFormatters'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    TRAVEL_DOCUMENTATION_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import Button from 'components/Button'
import FocusError from 'components/FocusError'
import DateTimeField from 'components/formFields/DateTimeField'
import FileUploadField from 'components/formFields/FileUploadField'
import InputField from 'components/formFields/InputField'
import RadioGroupField from 'components/formFields/RadioGroupField'
import SelectField from 'components/formFields/SelectField'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import ModalButtons from 'components/modal/ModalButtons'
import ModalTitle from 'components/modal/ModalTitle'
import Separator from 'components/Separator'
import COLORS from 'constants/colors'
import RecordForm from './RecordForm'
import VisaForm from './VisaForm'
import VisaCard from './VisaCard'
import RecordCard from './RecordCard'
import CardContainer from 'components/CardContainer'
import Accordion from 'components/Accordion'

const { PASSPORT, SEAMANS_BOOK } = TRAVEL_DOCUMENTATION_TYPE

const TravelDocumentFormContext = ({
    initialValues,
    setVisas,
    setRecords,
    setReloadFiles,
    setShowVisaForm,
    setShowRecordForm,
}) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setTouched } = formikContext

    const { travelDocumentationType, ...formFields } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            Object.keys(formFields).forEach((key) => {
                setFieldValue(key, initialValues[key])
            })
            setTouched({})
            setVisas([])
            setRecords([])
            setReloadFiles((reload) => !reload)
            setShowVisaForm(false)
            setShowRecordForm(false)
        } else {
            firstRender.current = false
        }
    }, [travelDocumentationType])

    return null
}

const TravelDocumentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [reloadFiles, setReloadFiles] = useState(false)
    const [showVisaForm, setShowVisaForm] = useState(false)
    const [editVisa, setEditVisa] = useState()
    const [showRecordForm, setShowRecordForm] = useState(false)
    const [editRecord, setEditRecord] = useState()
    const [visas, setVisas] = useState(initialData?.visas ?? [])
    const [records, setRecords] = useState(
        initialData?.recordInformations ?? []
    )
    const [submitted, setSubmitted] = useState(false)

    const formRef = useRef()

    const { data: documentationTypes, isLoading } = useFetchData(
        ENTITIES.TRAVEL_DOCUMENTATION_TYPE,
        {},
        true,
        false
    )

    useEffect(() => {
        const scrollToForm = (formClass) => {
            const form = document.querySelector(formClass)
            if (form) {
                form.scrollIntoView({ behavior: 'smooth' })
            }
        }

        if (editVisa) {
            scrollToForm('.-visaForm')
        }
        if (editRecord) {
            scrollToForm('.-recordForm')
        }
    }, [editVisa, editRecord])

    if (isLoading || !documentationTypes.length) return null

    const initialValues = {
        travelDocumentationType:
            initialData?.travelDocumentationType ?? documentationTypes[0],
        documentNumber: initialData?.documentNumber ?? '',
        dateOfExpiatory: initialData?.dateOfExpiatory ?? '',
        countryIssue: initialData?.countryIssue ?? null,
        files: initialData?.files ?? [],
        serviceBookId: initialData?.serviceBookId ?? '',
        dateOfIssuance: initialData?.dateOfIssuance ?? '',
        issuingAuthority: initialData?.issuingAuthority ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        travelDocumentationType: Yup.object().required(requiredMessage),
        documentNumber: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id !== SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),

            otherwise: () => Yup.string(),
        }),
        dateOfExpiatory: Yup.date()
            .min(getTodaysDate(), t('form.error.expirationDateInPast'))
            .test('dateOfExpiatory', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.dateOfIssuance,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.expirationAfterIssueDate'),
                        path: 'dateOfExpiatory',
                    })
                }
                if (isDateBefore(value, getTodaysDate(), MOMENT_FORMATS.DATE)) {
                    return createError({
                        message: t('form.error.expirationAfterCurrentDate'),
                        path: 'dateOfExpiatory',
                    })
                }
                return true
            })
            .required(requiredMessage),
        countryIssue: Yup.object().when('travelDocumentationType', {
            is: (val) => val.id !== SEAMANS_BOOK,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        files: Yup.array()
            .min(1, t('form.error.atLeastOnePersonalDocument'))
            .required(requiredMessage),
        serviceBookId: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),

            otherwise: () => Yup.string(),
        }),
        dateOfIssuance: Yup.date().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () =>
                Yup.date()
                    .test(
                        'dateOfIssuance',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBeforeCurrentDate(
                                    value,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return true
                            }
                            return createError({
                                message: t('form.error.dateOfIssuanceInFuture'),
                                path: 'dateOfIssuance',
                            })
                        }
                    )
                    .required(requiredMessage),
            otherwise: () => Yup.date(),
        }),
        issuingAuthority: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),
            otherwise: () => Yup.string(),
        }),
    })

    const submitFormData = async (formData, setSubmitting) => {
        try {
            setSubmitting(true)
            setSubmitted(true)

            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
                visas: visas.map((item) =>
                    formatRequestData(ENTITIES.VISA, item)
                ),
                recordInformations: records.map((item) =>
                    formatRequestData(ENTITIES.RECORD_INFORMATION, item)
                ),
            })

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
            setSubmitted(false)
        }
    }

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        if (showVisaForm || showRecordForm) {
            showConfirmationModal({
                title: 'general.savePersonalDocument',
                message: `${
                    showVisaForm
                        ? 'message.areYouSureSavingVisa'
                        : 'message.areYouSureSavingSeaman'
                }`,
                handleConfirm: () => {
                    submitFormData(formData, setSubmitting)
                    closeConfirmationModal()
                },
                handleCancel: () => closeConfirmationModal(),
            })
        } else {
            submitFormData(formData, setSubmitting)
        }
    }

    const handleClose = () => setOpen(false)

    const handleSubmitForm = () => formRef?.current?.handleSubmit()

    const handleShowVisaForm = () => setShowVisaForm(true)

    const handleShowRecordForm = () => setShowRecordForm(true)

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            closeOnClickOutside={false}
            title={title}
            formInForm
        >
            <div className="_wr">
                <div className="-body">
                    <div className="_w -bodyContent">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validation}
                            onSubmit={onSubmit}
                            innerRef={formRef}
                        >
                            {({
                                values: {
                                    travelDocumentationType,
                                    dateOfIssuance,
                                },
                                isSubmitting,
                            }) => {
                                const isPassport =
                                    travelDocumentationType.id === PASSPORT
                                const isSeamansBook =
                                    travelDocumentationType.id === SEAMANS_BOOK

                                return (
                                    <Form>
                                        <FocusError />
                                        <TravelDocumentFormContext
                                            initialValues={initialValues}
                                            setVisas={setVisas}
                                            setRecords={setRecords}
                                            setReloadFiles={setReloadFiles}
                                            setShowVisaForm={setShowVisaForm}
                                            setShowRecordForm={
                                                setShowRecordForm
                                            }
                                        />
                                        <div className="_w">
                                            <div className="_12">
                                                <RadioGroupField
                                                    name="travelDocumentationType"
                                                    label="form.label.travelDocumentationType"
                                                    defaultOptions={
                                                        documentationTypes
                                                    }
                                                    descriptionAttribute="note"
                                                    disabled={!!initialData}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="_w">
                                            <Separator />
                                            {!isPassport && !isSeamansBook && (
                                                <h6 className="-mb20 _12">
                                                    {t(
                                                        'general.nationalIdInformation'
                                                    )}
                                                </h6>
                                            )}
                                            {isPassport && (
                                                <h6 className="-mb20 _12">
                                                    {t(
                                                        'general.passportInformation'
                                                    )}
                                                </h6>
                                            )}
                                            {isSeamansBook && (
                                                <h6 className="-mb20 _12">
                                                    {t(
                                                        'general.seamanBookInformation'
                                                    )}
                                                </h6>
                                            )}
                                            {!isSeamansBook && (
                                                <div className="_12 _m6">
                                                    <InputField
                                                        name="documentNumber"
                                                        label="form.label.documentNumber"
                                                        placeholder="form.placeholder.documentNumber"
                                                        required
                                                    />
                                                </div>
                                            )}
                                            {isSeamansBook && (
                                                <Fragment>
                                                    <div className="_12 _m6">
                                                        <InputField
                                                            name="serviceBookId"
                                                            label="form.label.serviceBookId"
                                                            placeholder="form.placeholder.serviceBookId"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="_12 _m6 calendarMovedToRight">
                                                        <DateTimeField
                                                            name="dateOfIssuance"
                                                            label="form.label.dateOfIssuance"
                                                            maxDate={
                                                                new Date(
                                                                    getTodaysDate()
                                                                )
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </Fragment>
                                            )}
                                            <div className="_12 _m6 calendarMovedToRight">
                                                <DateTimeField
                                                    name="dateOfExpiatory"
                                                    label="form.label.dateOfExpiatory"
                                                    minDate={getTodaysDate()}
                                                    required
                                                />
                                            </div>
                                            {!isSeamansBook && (
                                                <div className="_12 _m6 -z2">
                                                    <SelectField
                                                        name="countryIssue"
                                                        label="form.label.countryIssue"
                                                        placeholder="form.placeholder.countryIssue"
                                                        entityType={
                                                            ENTITIES.COUNTRY
                                                        }
                                                        searchable
                                                        required
                                                        dropup={!isPassport}
                                                    />
                                                </div>
                                            )}
                                            {isSeamansBook && (
                                                <div className="_12 _m6">
                                                    <InputField
                                                        name="issuingAuthority"
                                                        label="form.label.issuingAuthority"
                                                        placeholder="form.placeholder.issuingAuthority"
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="_w -z1 -pb20">
                                            <Separator />
                                            <FileUploadField
                                                name="files"
                                                label="form.label.travelDocumentationFiles"
                                                fileCategory={
                                                    FILE_CATEGORIES.TRAVEL_DOCUMENTATION
                                                }
                                                reload={reloadFiles}
                                                required
                                            />
                                        </div>
                                        {isPassport && (
                                            <Fragment>
                                                {visas.length === 0 && (
                                                    <div className="_w">
                                                        <div className="_12">
                                                            <span className="a-bodyTextRegular">
                                                                {t(
                                                                    'general.addedVisas'
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="_12">
                                                            <div className="column fullWidth">
                                                                <span className="a-captionsTextRegular centered-text fullWidth">
                                                                    {t(
                                                                        'general.noItems'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {visas.length > 0 && (
                                                    <div className="_w">
                                                        <div className="_12">
                                                            <div className="column fullWidth">
                                                                <CardContainer
                                                                    noBorderCard
                                                                >
                                                                    <Accordion
                                                                        title="general.addedVisas"
                                                                        openByDefault={
                                                                            false
                                                                        }
                                                                        isLoading={
                                                                            showVisaForm
                                                                        }
                                                                        withBorders
                                                                    >
                                                                        {visas.map(
                                                                            (
                                                                                visa,
                                                                                index
                                                                            ) => (
                                                                                <VisaCard
                                                                                    visas={
                                                                                        visas
                                                                                    }
                                                                                    visa={
                                                                                        visa
                                                                                    }
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    handleShowVisaForm={
                                                                                        handleShowVisaForm
                                                                                    }
                                                                                    setVisas={
                                                                                        setVisas
                                                                                    }
                                                                                    setEditVisa={
                                                                                        setEditVisa
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                    </Accordion>
                                                                </CardContainer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                        {isSeamansBook && (
                                            <Fragment>
                                                {records.length === 0 && (
                                                    <div className="_w">
                                                        <div className="_12">
                                                            <span className="a-bodyTextRegular">
                                                                {t(
                                                                    'general.addedRecords'
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="_12">
                                                            <div className="column fullWidth">
                                                                <span className="a-captionsTextRegular centered-text fullWidth">
                                                                    {t(
                                                                        'general.noItems'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {records.length > 0 && (
                                                    <div className="_w">
                                                        <div className="_12">
                                                            <div className="column fullWidth">
                                                                <CardContainer
                                                                    noBorderCard
                                                                >
                                                                    <Accordion
                                                                        title="general.addedRecords"
                                                                        openByDefault={
                                                                            false
                                                                        }
                                                                        isLoading={
                                                                            showRecordForm
                                                                        }
                                                                        withBorders
                                                                    >
                                                                        {records.map(
                                                                            (
                                                                                record,
                                                                                index
                                                                            ) => (
                                                                                <RecordCard
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    record={
                                                                                        record
                                                                                    }
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                    records={
                                                                                        records
                                                                                    }
                                                                                    setRecords={
                                                                                        setRecords
                                                                                    }
                                                                                    handleShowRecordForm={
                                                                                        handleShowRecordForm
                                                                                    }
                                                                                    setEditRecord={
                                                                                        setEditRecord
                                                                                    }
                                                                                    editRecord={
                                                                                        editRecord
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                    </Accordion>
                                                                </CardContainer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                        {isPassport && !showVisaForm && (
                                            <div className="_w -mt30 -pb20">
                                                <div className="_12">
                                                    <Button
                                                        btnClass={
                                                            BUTTON_STATUS.SECONDARY
                                                        }
                                                        type={
                                                            BUTTON_TYPE.BUTTON
                                                        }
                                                        label="button.addVisa"
                                                        icon={ICONS.PLUS}
                                                        iconColor={
                                                            COLORS.LIGHT_BLUE
                                                        }
                                                        onClick={
                                                            handleShowVisaForm
                                                        }
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {isSeamansBook && !showRecordForm && (
                                            <div className="_w -mt30 -pb20">
                                                <div className="_12">
                                                    <Button
                                                        btnClass={
                                                            BUTTON_STATUS.SECONDARY
                                                        }
                                                        type={
                                                            BUTTON_TYPE.BUTTON
                                                        }
                                                        label="button.addRecordInformation"
                                                        icon={ICONS.PLUS}
                                                        iconColor={
                                                            COLORS.LIGHT_BLUE
                                                        }
                                                        onClick={
                                                            handleShowRecordForm
                                                        }
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {isSubmitting && <Loader />}
                                    </Form>
                                )
                            }}
                        </Formik>
                        {showVisaForm && (
                            <div className="-pb20">
                                <VisaForm
                                    setVisas={setVisas}
                                    setShowVisaForm={setShowVisaForm}
                                    visas={visas}
                                    editVisa={editVisa}
                                    setEditVisa={setEditVisa}
                                />
                            </div>
                        )}
                        {showRecordForm && (
                            <div className="-pb20">
                                <RecordForm
                                    setRecords={setRecords}
                                    setShowRecordForm={setShowRecordForm}
                                    records={records}
                                    editRecord={editRecord}
                                    setEditRecord={setEditRecord}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalButtons
                setOpen={setOpen}
                buttons={{
                    prevBtn: {
                        disabled: submitted,
                    },
                    nextBtn: {
                        label: 'button.savePersonalDocument',
                        disabled: submitted,
                        handleClick: handleSubmitForm,
                    },
                }}
            />
        </Modal>
    )
}

TravelDocumentForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
}

export default TravelDocumentForm
