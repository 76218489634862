import { useTranslate } from 'react-polyglot'
import { useContext, useState } from 'react'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { editEntityService } from 'services/entity.service'

import useLogout from 'hooks/useLogout'

import Modal from 'components/Modal'
import Input from 'components/Input'

import { ALERT_TYPES, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

const CancelTermsAndConditions = ({
    termsAndConditionsModal,
    setTermsAndConditionsModal,
}) => {
    const t = useTranslate()

    const { handleLogout } = useLogout()

    const { setAlert } = useContext(AlertContext)
    const [inputValue, setInputValue] = useState('')

    const { userId } = useContext(CurrentUserContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleCancel = () => {
        setTermsAndConditionsModal(false)
    }

    const handleConfirm = () => {
        closeConfirmationModal()
        handleLogout()
    }

    const deleteAccount = async () => {
        if (inputValue === t('general.cancel')) {
            try {
                await editEntityService(ENTITIES.USER, String(userId), {
                    isCancelTermsAndConditions: true,
                })
                showConfirmationModal({
                    title: 'general.success',
                    message: 'message.permanentlyDeleted',
                    icon: ICONS.CHECKMARK_CIRCLE,
                    iconColor: COLORS.GREEN_60,
                    iconSize: ICON_SIZE.SIZE80,
                    confirmLabel: 'button.okClose',
                    handleConfirm: () => handleConfirm(),
                    hideCancel: true,
                })
                setTermsAndConditionsModal(false)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } else {
            setAlert(
                { message: t('general.pleaseTypeCancel') },
                ALERT_TYPES.ERROR
            )
        }
    }

    return (
        <Modal
            open={termsAndConditionsModal}
            setOpen={setTermsAndConditionsModal}
            title="general.cancelTermsAndConditions"
            smallModal
            buttons={{
                prevBtn: {
                    handleClick: handleCancel,
                    label: 'button.goBackFromModal',
                },
                nextBtn: {
                    handleClick: deleteAccount,
                    label: 'button.deleteMyAccount',
                    disabled: inputValue !== t('general.cancel'),
                    btnClass: BUTTON_STATUS.DANGER_RED,
                },
            }}
        >
            <div className="_wr">
                <p className="a-mediumText a-lightText -mb20 centered-text">
                    {t('general.cancelTermsAndConditionsText')}
                </p>
                <p className="a-mediumText a-lightText -mb30 centered-text">
                    {t('general.cancelTermsAndConditionsText2')}
                </p>
            </div>
            <div className="_12">
                <Input
                    name="inputText"
                    showLabel={false}
                    placeholder="form.placeholder.cancel"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </div>
        </Modal>
    )
}

export default CancelTermsAndConditions
