import { useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'

import { NotificationContext } from 'contexts/NotificationContext'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useCanLeavePage from 'hooks/useCanLeavePage'

import { editEntityService } from 'services/entity.service'
import { getDateTimeAgo } from 'services/moment.service'

import {
    NOTIFICATION_TYPE,
    notificationNavigation,
    renderImageBoxBadge,
    setImageBoxClassName,
} from 'utils/notificationHelper'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import NotificationImageBox from './NotificationImageBox'
import ROUTES from 'constants/routes'

const NotificationItem = ({
    displayNotifications,
    setDisplayNotifications,
    handleOpenNotifications,
    fetchNotifications,
    isVerified,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const currentLocation = window.location.pathname

    const { setReloadUnreadNotifications } = useContext(NotificationContext)
    const { currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const handleReadUnread = async (e, itemID, seen) => {
        e.stopPropagation()

        try {
            await editEntityService(ENTITIES.NOTIFICATIONS, itemID, {
                seen: !seen,
            })

            const index = displayNotifications.findIndex(
                (notification) => notification.id === itemID
            )

            const notificationsCopy = [...displayNotifications]
            notificationsCopy[index] = {
                ...notificationsCopy[index],
                seen: !seen,
            }

            setDisplayNotifications(notificationsCopy)
            fetchNotifications()
            setReloadUnreadNotifications((state) => !state)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClickNotification = (
        e,
        entityId,
        notificationType,
        user,
        seen,
        id,
        additionalData,
        disabled
    ) => {
        const isNotDefinedNotificationTypes = !Object.values(
            NOTIFICATION_TYPE
        ).includes(notificationType)

        if (disabled || isNotDefinedNotificationTypes) {
            setAlert(t('message.notificationIsUnavailable'), ALERT_TYPES.ERROR)
            !seen && handleReadUnread(e, id, seen)
            handleOpenNotifications()
            return
        }

        if (!canLeavePage) {
            handleShowModal(
                notificationNavigation(
                    entityId,
                    notificationType,
                    currentUser.profileHash,
                    additionalData?.projectPositionId,
                    additionalData
                )
            )
        } else {
            const newUrl = notificationNavigation(
                entityId,
                notificationType,
                currentUser.profileHash,
                additionalData?.projectPositionId,
                additionalData
            )

            if (!isVerified && newUrl.includes(ROUTES.PROJECT)) {
                setAlert(
                    t('message.onlyAllowedForVerifiedUsers'),
                    ALERT_TYPES.NOTIFICATION
                )
                return
            }

            navigate(newUrl)
            if (newUrl === currentLocation) {
                setTimeout(() => window.location.reload(), 1000)
            }
        }

        !seen && handleReadUnread(e, id, seen)
        handleOpenNotifications()
    }

    return (
        <div>
            {displayNotifications?.map(
                ({
                    id,
                    description,
                    createdAt,
                    seen,
                    entityId,
                    notificationLevel,
                    notificationType,
                    user,
                    additionalData,
                    disabled,
                }) => (
                    <div
                        key={id}
                        className={`m-dropdown__notification ${
                            !seen ? '-unread' : ''
                        }`}
                        onClick={(e) =>
                            handleClickNotification(
                                e,
                                entityId,
                                notificationType,
                                user,
                                seen,
                                id,
                                additionalData,
                                disabled
                            )
                        }
                    >
                        <div
                            className={`-imageBox ${setImageBoxClassName(
                                notificationLevel,
                                notificationType
                            )}`}
                        >
                            <NotificationImageBox
                                notificationLevel={notificationLevel}
                                notificationType={notificationType}
                                avatarPath={additionalData?.avatarPath}
                            />

                            <div
                                className={`-badge ${renderImageBoxBadge(
                                    notificationType
                                )}`}
                            >
                                <div className="-img"></div>
                            </div>
                        </div>
                        <div className="-info">
                            <div>
                                <p
                                    className="a-captionsTextRegular"
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                ></p>
                            </div>
                            <div>
                                <span className="-date a-captionsTextRegular a-lightText -opacity-60">
                                    {getDateTimeAgo(createdAt)}
                                </span>
                                <span
                                    className="a-captionsTextRegular -readUnread"
                                    onClick={(e) =>
                                        handleReadUnread(e, id, seen)
                                    }
                                >
                                    {seen
                                        ? t('general.markAsUnread')
                                        : t('general.markAsRead')}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    )
}

NotificationItem.propTypes = {
    displayNotifications: PropTypes.array.isRequired,
    setDisplayNotifications: PropTypes.func,
    handleOpenNotifications: PropTypes.func,
    fetchNotifications: PropTypes.func,
    isVerified: PropTypes.bool,
}

export default NotificationItem
