import ROUTES from 'constants/routes'
import { COMPANY_ROLES, ROLES } from 'constants/enums'

import {
    AdminLayout,
    AuthLayout,
    FullScreenLayout,
    NoAuthLayout,
} from 'components/layouts'
import { MyDiveProjects } from 'screens/diver/MyDiveProjects'
import {
    DiveProfileRecord,
    DiveRecord,
    DiveSummaryRecord,
    EquipmentRecord,
    GeneralInformationRecord,
    WorkPerformedRecord,
    RecordVerification,
    GeneralInformationVerification,
    EquipmentVerification,
    DiveProfileVerification,
    WorkPerformedVerification,
    DiveSummaryVerification,
} from 'screens/diver/diveRecord'
import {
    DeclineEmailChange,
    MedicalInfoAccessControl,
    Notifications,
    PastEmploymentAccessControl,
    PersonalEquipmentAccessControl,
    Settings,
    SignInAndSecurity,
    TrainingRecordsAccessControl,
    TravelDocumentsAccessControl,
    UnitsAndFormats,
} from 'screens/diver/settings'
import MyDiveRecords from 'screens/diver/myDiveRecords/MyDiveRecords'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { GalleryPage } from 'screens/common/gallery'
import { ChatPage } from 'screens/common/chat/ChatPage'
import { Homepage as DiverHomepage } from 'screens/diver/Homepage'
import {
    CreateProject,
    Homepage as DivingContractorHomepage,
    EditProject,
    ProjectDiveTeam,
} from 'screens/divingContractor'
import { AccessDeniedPage, NotFoundPage, SingleProject } from 'screens/common'
import ProjectDiveRecords from 'screens/common/projectDiveRecords/ProjectDiveRecords'
import { ProfileScreen as DiverProfile } from 'screens/diver/profile'
import { ProfileScreen as ContractorProfile } from 'screens/divingContractor'
import {
    DiveRecordVerificationLevels,
    HelpAndInfo,
} from 'screens/common/helpAndInfo'
import Release090 from '../../screens/common/helpAndInfo/release/Release090'
import Release091 from '../../screens/common/helpAndInfo/release/Release091'
import Release100 from 'screens/common/helpAndInfo/release/Release100'
import Release110 from 'screens/common/helpAndInfo/release/Release110'
import Release120 from 'screens/common/helpAndInfo/release/Release120'
import Release130 from 'screens/common/helpAndInfo/release/Release130'
import Release140 from 'screens/common/helpAndInfo/release/Release140'
import Release150 from 'screens/common/helpAndInfo/release/Release150'
import Release160 from 'screens/common/helpAndInfo/release/Release160'

import Register from 'screens/noAuth/Register'
import GeneralPreferences from 'screens/diver/settings/GeneralPreferences'
import DiveRecordsAccessControl from 'screens/diver/settings/DiveRecordsAccessControl'
import ExportCvAccessControl from 'screens/diver/settings/ExportCvAccessControl'
import AccountManagement from 'screens/diver/settings/accountManagement/AccountManagement'
import Badges from 'screens/common/helpAndInfo/Badges'
import CreateAProject from 'screens/common/helpAndInfo/guids/CreateAProject'
import CreateHistoricalDiveRecord from 'screens/common/helpAndInfo/guids/CreateHistoricalDiveRecord'
import CreatePersonalAccount from 'screens/common/helpAndInfo/guids/CreatePersonalAccount'
import SendDiveRecordForVerificationLevelOne from 'screens/common/helpAndInfo/guids/SendDiveRecordForVerificationLevelOne'
import SignUpAsOrganization from 'screens/common/helpAndInfo/guids/SignUpAsOrganization'
import UpdateOrganizationProfile from 'screens/common/helpAndInfo/guids/UpdateOrganizationProfile'
import UpdatePersonalInformation from 'screens/common/helpAndInfo/guids/UpdatePersonalInformation'
import VerifyDiveRecordAsASupervisorLevelOne from 'screens/common/helpAndInfo/guids/VerifyDiveRecordAsASupervisorLevelOne'
import VerifyIdentityUsingPersona from 'screens/common/helpAndInfo/guids/VerifyIdentityUsingPersona'
import AdciSearch from 'screens/common/adci/components/AdciSearch'
import PackagesPage from 'screens/common/PackagesPage'
import Support from 'screens/common/helpAndInfo/Support'
import DiveCertificationVerification from 'screens/common/helpAndInfo/cheknDepth/DiveCertificationVerification'
import Faq from 'screens/common/helpAndInfo/FAQ'
import Analytics from 'screens/divingContractor/analytics/Analytics'
import GalleryImagesPolicyPage from 'screens/common/helpAndInfo/galleryImagesPolicy/GalleryImagesPolicyPage'
import DiveRecordStatusesPage from 'screens/common/helpAndInfo/explore/DiveRecordStatusesPage'
import Subscription from 'screens/diver/subscription/Subscription'
import BillingListing from 'screens/diver/subscription/BillingListing'
import ProjectsAccessControl from 'screens/diver/settings/ProjectsAccessControl'
import {
    IndividualAccountEdit,
    IndividualAccountManagement,
} from 'screens/admin/IndividualAccountManagement'
import {
    CompanyAccountEdit,
    CompanyAccountManagement,
} from 'screens/admin/CompanyAccountManagement'
import CallCenterManagement from 'screens/admin/CallCenter/CallCenterManagement'
import CallCenterView from 'screens/admin/CallCenter/CallCenterView'
import TermsAndConditions from 'screens/admin/TermsAndConditions/TermsAndConditions'
import OfflineModePage from 'screens/common/helpAndInfo/offlineMode/OfflineModePage'
import ContactInfoAccessControl from '../../screens/diver/settings/ContactInfoAccessControl'
import ProfileImageGuidelinesPage from 'screens/common/helpAndInfo/profileImageGuidelines/ProfileImageGuidelinesPage'
import { entityManagementRoutes } from './EntityManagementRoutes'
import CertificateQrDetails from 'screens/diver/DiverOnboarding/TrainingRecordsOnboarding/components/CertificateQrDetails'
import CertificatesVerificationPage from 'screens/common/helpAndInfo/explore/CertificatesVerificationPage'
import DownloadMobileApp from 'screens/common/helpAndInfo/DownloadMobileApp'

const {
    ROLE_DIVER,
    ROLE_SUPER_ADMIN,
    ROLE_DIVING_CONTRACTOR,
    ROLE_CLIENT_ORGANIZATION_ADMIN,
} = ROLES

const ALL_REAL_USER_ROLES = [
    ROLE_DIVER,
    ROLE_DIVING_CONTRACTOR,
    ROLE_CLIENT_ORGANIZATION_ADMIN,
]

const diveRecordRoutes = [
    {
        path: ROUTES.GENERAL_INFORMATION,
        element: <GeneralInformationRecord />,
    },
    {
        path: ROUTES.EQUIPMENT,
        element: <EquipmentRecord />,
    },
    {
        path: ROUTES.DIVE_PROFILE,
        element: <DiveProfileRecord />,
    },
    {
        path: ROUTES.WORK_PERFORMED,
        element: <WorkPerformedRecord />,
    },
    {
        path: ROUTES.DIVE_SUMMARY,
        element: <DiveSummaryRecord />,
    },
]

const diveRecordVerificationRoutes = [
    {
        path: ROUTES.GENERAL_INFORMATION,
        element: <GeneralInformationVerification />,
    },
    {
        path: ROUTES.EQUIPMENT,
        element: <EquipmentVerification />,
    },
    {
        path: ROUTES.DIVE_PROFILE,
        element: <DiveProfileVerification />,
    },
    {
        path: ROUTES.WORK_PERFORMED,
        element: <WorkPerformedVerification />,
    },
    {
        path: ROUTES.DIVE_SUMMARY,
        element: <DiveSummaryVerification />,
    },
    {
        path: ROUTES.VERIFICATION,
        element: <RecordVerification />,
    },
]

export const diveRecordFirstLevelVerificationRoute = {
    path: `${ROUTES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION}/:verificationToken`,
    element: <DiveRecord />,
    routes: diveRecordVerificationRoutes,
}

const authRoutes = [
    {
        element: <AuthLayout />,
        roles: ALL_REAL_USER_ROLES,
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <DiverHomepage />,
                roles: [ROLE_DIVER],
            },
            {
                path: ROUTES.HOME,
                element: <DivingContractorHomepage />,
                roles: [ROLE_DIVING_CONTRACTOR, ROLE_CLIENT_ORGANIZATION_ADMIN],
            },
            {
                path: ROUTES.MY_DIVE_PROJECTS,
                element: <MyDiveProjects />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: ROUTES.DIVE_RECORDS,
                element: <MyDiveRecords />,
                roles: [ROLE_DIVER],
            },
            {
                path: ROUTES.ANALYTICS,
                element: <Analytics />,
                roles: COMPANY_ROLES,
            },
            {
                path: ROUTES.SETTINGS,
                element: <Settings />,
                roles: ALL_REAL_USER_ROLES,
                routes: [
                    {
                        path: ROUTES.UNITS_OF_MEASUREMENT,
                        element: <UnitsAndFormats />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.CONTACT_INFO}`,
                        element: <ContactInfoAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.DIVE_RECORD}`,
                        element: <DiveRecordsAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}${ROUTES.PROJECT}`,
                        element: <ProjectsAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.MEDICAL_INFO}`,
                        element: <MedicalInfoAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAVEL_DOCUMENTATION}`,
                        element: <TravelDocumentsAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAINING_RECORDS}`,
                        element: <TrainingRecordsAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PERSONAL_EQUIPMENT}`,
                        element: <PersonalEquipmentAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PAST_EMPLOYMENT}`,
                        element: <PastEmploymentAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.EXPORT_CV}`,
                        element: <ExportCvAccessControl />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: ROUTES.GENERAL_PREFERENCES,
                        element: <GeneralPreferences />,
                    },
                    {
                        path: ROUTES.ACCOUNT_MANAGEMENT,
                        element: <AccountManagement />,
                    },
                    {
                        path: ROUTES.SIGN_IN_AND_SECURITY,
                        element: <SignInAndSecurity />,
                    },
                    {
                        path: ROUTES.NOTIFICATIONS,
                        element: <Notifications />,
                        roles: [ROLE_DIVER],
                    },
                    {
                        path: `${ROUTES.BILLING}`,
                        element: <BillingListing />,
                        roles: [ROLE_DIVER],
                    },
                ],
            },
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.DIVE_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: ROUTES.GALLERY,
                element: <GalleryPage />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: ROUTES.CHECK_CERTIFICATES,
                element: <AdciSearch />,
                roles: COMPANY_ROLES,
            },
            {
                path: ROUTES.CHAT,
                element: <ChatPage />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}/:id`,
                element: <SingleProject />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}/:id/${ROUTES.DIVE_RECORDS}`,
                element: <ProjectDiveRecords />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:id`,
                element: <SingleProject />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:id/${ROUTES.MY_PROJECT_RECORDS}`,
                element: <ProjectDiveRecords />,
                roles: ALL_REAL_USER_ROLES,
                requireVerification: true,
            },
            {
                path: `${ROUTES.DIVER_PROFILE}/:profileHash`,
                element: <DiverProfile />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: `${ROUTES.DIVING_CONTRACTOR_PROFILE}/:profileHash`,
                element: <ContractorProfile />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.MY_PROJECT_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: `${ROUTES.SETTINGS}/${ROUTES.ACCOUNT_MANAGEMENT}`,
                element: <AccountManagement />,
                roles: ALL_REAL_USER_ROLES,
            },
            {
                path: ROUTES.HELP_AND_INFO,
                element: <HelpAndInfo />,
                roles: ALL_REAL_USER_ROLES,
                routes: [
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_A_PROJECT}`,
                        element: <CreateAProject />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_HISTORICAL_DIVE_RECORD}`,
                        element: <CreateHistoricalDiveRecord />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_PERSONAL_ACCOUNT}`,
                        element: <CreatePersonalAccount />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE}`,
                        element: <SendDiveRecordForVerificationLevelOne />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.SIGN_UP_AS_ORGANIZATION}`,
                        element: <SignUpAsOrganization />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_ORGANIZATION_PROFILE}`,
                        element: <UpdateOrganizationProfile />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_PERSONAL_INFORMATION}`,
                        element: <UpdatePersonalInformation />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE}`,
                        element: <VerifyDiveRecordAsASupervisorLevelOne />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_IDENTITY_USING_PERSONA}`,
                        element: <VerifyIdentityUsingPersona />,
                    },
                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
                        element: <DiveRecordVerificationLevels />,
                    },
                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.DIVE_RECORD_STATUSES}`,
                        element: <DiveRecordStatusesPage />,
                    },
                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.CERTIFICATES_VERIFICATION}`,
                        element: <CertificatesVerificationPage />,
                    },
                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.BADGES}`,
                        element: <Badges />,
                    },
                    {
                        path: `${ROUTES.CHECKN_DEPTH}/${ROUTES.DIVE_CERTIFICATION_VERIFICATION}`,
                        element: <DiveCertificationVerification />,
                    },
                    {
                        path: `${ROUTES.PROFILE_IMAGE_GUIDELINES}`,
                        element: <ProfileImageGuidelinesPage />,
                    },
                    {
                        path: `${ROUTES.GALLERY_IMAGES_POLICY}`,
                        element: <GalleryImagesPolicyPage />,
                    },
                    {
                        path: `${ROUTES.SUPPORT}`,
                        element: <Support />,
                    },
                    {
                        path: `${ROUTES.DOWNLOAD_MOBILE_APP}`,
                        element: <DownloadMobileApp />,
                    },
                    {
                        path: `${ROUTES.FAQ}`,
                        element: <Faq />,
                    },
                    {
                        path: `${ROUTES.OFFLINE_MODE}`,
                        element: <OfflineModePage />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`,
                        element: <Release090 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`,
                        element: <Release091 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`,
                        element: <Release100 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_1_0}`,
                        element: <Release110 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_2_0}`,
                        element: <Release120 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_3_0}`,
                        element: <Release130 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_4_0}`,
                        element: <Release140 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_5_0}`,
                        element: <Release150 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_6_0}`,
                        element: <Release160 />,
                    },
                ],
            },
            {
                path: `${ROUTES.PROJECT}/:id${ROUTES.DIVE_TEAM}`,
                element: <ProjectDiveTeam />,
                roles: [ROLE_DIVING_CONTRACTOR, ROLE_CLIENT_ORGANIZATION_ADMIN],
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}${ROUTES.CREATE}`,
                element: <CreateProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`,
                element: <CreateProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}${ROUTES.EDIT}/:id`,
                element: <EditProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.EDIT}/:id`,
                element: <EditProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: ROUTES.PACKAGES,
                element: <PackagesPage />,
            },
            {
                path: `${ROUTES.SUBSCRIPTION}/:plan`,
                element: <Subscription />,
                roles: [ROLE_DIVER],
            },
        ],
    },
    {
        element: <FullScreenLayout />,
        layout: true,
        routes: [
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
                routes: diveRecordRoutes,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
                routes: diveRecordRoutes,
            },
            {
                path: `/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER],
                routes: diveRecordRoutes,
            },
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD_SUMMARY}/:diveRecordId`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
                routes: diveRecordVerificationRoutes,
            },
            {
                path: `/${ROUTES.DIVE_RECORD_SUMMARY}/:diveRecordId`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
                routes: diveRecordVerificationRoutes,
            },
            {
                ...diveRecordFirstLevelVerificationRoute,
                roles: [ROLE_DIVER],
            },
            {
                path: `${ROUTES.DECLINE_EMAIL_CHANGE}/:hash`,
                element: <DeclineEmailChange />,
                roles: [ROLE_DIVER],
            },
            { path: ROUTES.NOT_FOUND_PAGE, element: <NotFoundPage /> },
            { path: ROUTES.ACCESS_DENIED, element: <AccessDeniedPage /> },
            {
                path: `${ROUTES.CERTIFICATE_QR}/:hash`,
                element: <CertificateQrDetails />,
            },
        ],
    },
    {
        element: <NoAuthLayout />,
        roles: ALL_REAL_USER_ROLES,
        layout: true,
        routes: [
            {
                path: ROUTES.REGISTER,
                element: <Register />,
                roles: ALL_REAL_USER_ROLES,
                onboarding: true,
            },
        ],
    },
    {
        element: <AdminLayout />,
        roles: [ROLE_SUPER_ADMIN],
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <IndividualAccountManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT,
                element: <IndividualAccountManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: `${ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT}${ROUTES.EDIT}/:userId`,
                element: <IndividualAccountEdit />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: ROUTES.COMPANY_ACCOUNT_MANAGEMENT,
                element: <CompanyAccountManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: `${ROUTES.COMPANY_ACCOUNT_MANAGEMENT}${ROUTES.EDIT}/:companyId`,
                element: <CompanyAccountEdit />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: ROUTES.TERMS_AND_CONDITIONS,
                element: <TermsAndConditions />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: ROUTES.CALL_CENTER,
                element: <CallCenterManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: `${ROUTES.CALL_CENTER}/:callId`,
                element: <CallCenterView />,
                roles: [ROLE_SUPER_ADMIN],
            },
            ...entityManagementRoutes,
        ],
    },
]

export { authRoutes }
