import React from 'react'
import { useTranslate } from 'react-polyglot'
import QRCode from 'react-qr-code'

import Modal from 'components/Modal'

const IdentityVerificationModal = ({ verificationUrl, setVerificationUrl }) => {
    const t = useTranslate()

    const initIdentityVerification = () => {
        window.location = verificationUrl
    }

    return (
        <Modal
            open={verificationUrl !== ''}
            setOpen={() => {
                setVerificationUrl('')
            }}
            title="identityVerification.modalTitle"
            smallModal
            buttons={{
                prevBtn: {
                    handleClick: () => {
                        setVerificationUrl('')
                    },
                },
                nextBtn: {
                    label: 'identityVerification.goToPersona',
                    handleClick: initIdentityVerification,
                },
            }}
        >
            <div className="_12">
                <div className="-mt10 a-mediumText a-lightText -mb20">
                    <div className="-mb20">
                        {t('identityVerification.modalDescription')}
                    </div>

                    <div>{t('identityVerification.modalOptions')}</div>
                </div>
            </div>
            <div className="_12">
                <div className="qrCodeBox -mt10">
                    <QRCode
                        size={180}
                        value={verificationUrl}
                        viewBox={`0 0 210 210`}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default IdentityVerificationModal
