import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { validatePhoneNumber } from 'utils/yupValidations'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { createEntityService } from 'services/entity.service'

import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { InputField, SelectField } from 'components/formFields'
import Loader from 'components/Loader'

const ChangePhoneNumberModal = ({ open, setOpen }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

    const initialValues = {
        countryPhoneCode: currentUser.countryPhoneCode ?? null,
        phoneNumber: currentUser.phoneNumber ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        countryPhoneCode: Yup.object().required(requiredMessage),
        phoneNumber: validatePhoneNumber(t, requiredMessage),
    })

    const handleSubmit = async (data, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const {
                data: { phoneNumber, countryPhoneCode },
                detail,
            } = await createEntityService(
                `${ENTITIES.USER}/${ENTITIES.CHANGE_PHONE_NUMBER}`,
                data,
                false
            )
            setCurrentUser((state) => ({
                ...state,
                phoneNumber,
                countryPhoneCode,
            }))
            setAlert(t(detail), ALERT_TYPES.SUCCESS)
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="button.changePhoneNumber"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                        smallModalWithDropdowns
                    >
                        <p className="_12 -mb20 a-centeredText a-mediumText a-lightText">
                            {t('general.enterYourNewPhoneNumber')}
                        </p>
                        <div className="_12 -mb10">
                            <div className="a-phoneNumberField aligned-baseline fakeLabel -notFirst">
                                <SelectField
                                    name="countryPhoneCode"
                                    label="form.label.newPhoneNumber"
                                    placeholder="form.placeholder.newPhoneNumber"
                                    entityType={ENTITIES.COUNTRY}
                                    displayAttribute="callingCode"
                                    searchAttribute="callingCode"
                                    iconAttribute="flag"
                                    params={{ sort: 'callingCode' }}
                                    searchable
                                    required
                                />
                                <InputField
                                    name="phoneNumber"
                                    label="general.fakeLabel"
                                    placeholder="form.placeholder.phone"
                                    icon={ICONS.PHONE}
                                    iconColor={COLORS.DARK_BLUE_60}
                                    size={ICON_SIZE.SIZE20}
                                    required
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

ChangePhoneNumberModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default ChangePhoneNumberModal
