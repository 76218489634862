import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { renderRoleNames } from 'utils/roleNamesHelper'

import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Image from 'components/Image'

const CustomUserOption = ({ item, showPositions }) => {
    return (
        <Fragment>
            <div className="a_customOptionItem__imageWrapper aligned-center justify-center -larger">
                <div className="-image aligned-center justify-center">
                    <Image
                        path={item.avatarPath || item.logo}
                        alt="user"
                        icon={ICONS.USER}
                        iconSize={ICON_SIZE.SIZE12}
                    />
                </div>
            </div>
            <div className="column">
                <span className="a-captionsTextRegular a-darkBlueText">
                    {item.fullName || item.name}
                </span>
                {showPositions && (
                    <span className="a-captionsTextRegular a-lightText -opacity-60 ellipsis-text-two-lines">
                        {renderRoleNames(
                            item.primaryPositions?.length > 0
                                ? item.primaryPositions
                                : item.positions?.slice(0, 3)
                        )}
                    </span>
                )}
            </div>
        </Fragment>
    )
}

CustomUserOption.propTypes = {
    item: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CustomUserOption
