import React from 'react'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const FileCell = ({ value, handleView }) => {
    return (
        <div
            className="aligned-center -gap5 -fileCell pointer"
            onClick={() => handleView(value)}
        >
            <Icon
                name={ICONS.DIVE_RECORDS}
                size={ICON_SIZE.SIZE20}
                color={COLORS.DARK_BLUE_60}
            />
            <span className="-cellValue">{value.file.originalName}</span>
        </div>
    )
}

export default FileCell
