import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import useQueryParams from 'hooks/useQueryParams'

import Modal from 'components/Modal'
import { InputField, SelectField } from 'components/formFields'
import { ENTITY_SOURCE } from 'constants/constants'
import { SELECT_VALUE_TYPE } from 'constants/enums'

const EntityFilters = ({ open, setOpen, activeFilters, entityType }) => {
    const t = useTranslate()

    const { addFilterParams } = useQueryParams()

    const emptyValues = {
        name: '',
        source: null,
    }

    const initialValues = {
        name: activeFilters.name ?? emptyValues.name,
        source: activeFilters.source ?? emptyValues.source,
    }

    const handleFilter = (filters) => {
        addFilterParams(filters)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={`${t('general.filter')} ${t(
                            `entityManagement.${entityType}`
                        ).toLowerCase()}`}
                        isSubmitting={isSubmitting}
                        values={values}
                        smallModal
                        smallModalWithDropdowns
                        noTranslateTitle
                        buttons={{
                            middleBtn: {
                                handleClick: () =>
                                    handleReset(
                                        values,
                                        setFieldValue,
                                        setTouched
                                    ),
                            },
                            nextBtn: {
                                label: 'button.filter',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12">
                                    <InputField
                                        name="name"
                                        label="form.label.entityName"
                                        placeholder="form.placeholder.entityName"
                                    />
                                </div>
                                <div className="_12">
                                    <SelectField
                                        name="source"
                                        label="form.label.source"
                                        placeholder="form.placeholder.source"
                                        defaultOptions={ENTITY_SOURCE}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        searchable
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default EntityFilters
