import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

const Avatar = ({
    firstName,
    lastName,
    fullName,
    avatar,
    companyAvatar,
    size,
    centered,
    showFullName,
}) => {
    const name = fullName || `${firstName} ${lastName}`

    return (
        <div className={`m-avatar__imgInfo ${centered ? '-centered' : ''}`}>
            <div
                className={`m-avatar__img -h${size} -w${size} -minh${size} -minw${size}`}
            >
                <span className="m-avatar__icon">
                    <Image
                        path={avatar}
                        alt="avatar"
                        icon={companyAvatar ? ICONS.BUILDING : ICONS.USER}
                        iconColor={COLORS.WHITE}
                    />
                </span>
            </div>
            <div className="m-avatar__info">
                <span
                    className={`m-avatar__name ${
                        showFullName ? '' : '-ellipsis'
                    }`}
                    title={name}
                >
                    {name}
                </span>
            </div>
        </div>
    )
}

Avatar.propTypes = {
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    companyAvatar: PropTypes.bool,
    size: PropTypes.number,
    centered: PropTypes.bool,
    showFullName: PropTypes.bool,
}

Avatar.defaultProps = {
    size: ICON_SIZE.SIZE30,
    centered: true,
    showFullName: false,
}

export default Avatar
