import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import Cropper from 'react-easy-crop'
import heic2any from 'heic2any'

import { AlertContext } from 'contexts/AlertContext'

import { formatResponseData } from 'utils/jsonApiFormatters'
import axios, { BASE_URL, POST } from 'utils/axiosClient'
import { getUserToken } from 'services/localStorage.service'

import useCropImage from 'hooks/useCropImage'

import { ALERT_TYPES, BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ENTITIES from 'constants/entities'
import COLORS from 'constants/colors'

import Modal from '../Modal'
import Button from '../Button'
import Loader from 'components/Loader'

const CoverImageUploadFields = ({
    name,
    open,
    setOpen,
    title,
    handleChildSubmit,
    setSubmitting,
    image,
    fileCategory,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const [imageSrc, setImageSrc] = useState(null)
    const [uploadedFile, setUploadedFile] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [loading, setLoading] = useState(false)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    let fileType = ''

    if (image) {
        fileType = image.fileType
    }

    const inputFile = useRef(null)

    const { getCroppedImg } = useCropImage()

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const preparePrivateFile = async (item) => {
        const fileUrl = BASE_URL + '/api/files/get/' + item?.id
        setLoading(true)
        try {
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            })

            const blobData = await response.blob()

            setImageSrc(URL.createObjectURL(blobData))
        } catch (error) {
            // Handle error
            console.error('Error fetching file:', error)
        } finally {
            // Set loading state to false when done (whether successful or not)
            setLoading(false)
        }
    }

    useEffect(() => {
        const getPrivateFiles = async () => {
            if (
                fileType &&
                (fileType !== 'image/png' ||
                    fileType !== 'image/jpeg' ||
                    fileType !== 'image/jpg' ||
                    fileType !== 'application/octet-stream')
            ) {
                await preparePrivateFile(image)
            }
        }
        getPrivateFiles()
    }, [])

    const handleApply = async () => {
        if (!uploadedFile && !imageSrc) {
            try {
                handleChildSubmit(null, setSubmitting)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } else {
            const formData = new FormData()
            const fileName = uploadedFile.name || image.name

            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )
            const response = await fetch(croppedImage)
            const blob = await response.blob()
            const file = new File([blob], fileName, {
                type: uploadedFile.type || image.fileType,
            })

            formData.append('file', file, fileName)
            formData.append('category', fileCategory)

            axios(POST, `/${ENTITIES.FILE}/upload`, formData)
                .then(async ({ data }) => {
                    let file = data
                    const { data: uploadedFile } = formatResponseData(data)
                    file = uploadedFile
                    handleChildSubmit(file, setSubmitting)
                })
                .catch((error) => {
                    setAlert(error, ALERT_TYPES.ERROR, t)
                })
        }
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let file = e.target.files[0]

            // Check if file type is .gif and handle accordingly
            if (file.type === 'image/gif') {
                setAlert(t('general.gif'), ALERT_TYPES.ERROR, t)
                return
            }

            if (['image/heic', 'image/heif'].includes(file.type)) {
                const name = file.name.replace('.heic', '.jpeg')
                file = await heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                })

                file.name = name
            }

            const imageDataUrl = await readFile(file)

            setImageSrc(imageDataUrl)
            setUploadedFile(file)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const deleteCoverPhoto = async () => {
        try {
            // Reset state variables
            setImageSrc(null)
            setUploadedFile('')
            setCrop({ x: 0, y: 0 })
            setZoom(1)
            setCroppedAreaPixels(null)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    // Input range min = 1 and max = 3
    const progress = ((zoom - 1) / (3 - 1)) * 100

    return (
        <div>
            <Modal
                open={open}
                setOpen={setOpen}
                customClass="-coverImage"
                title={title}
            >
                <div className="_12">
                    <div className="coverImage__cropper">
                        {!imageSrc && (
                            <div className="m-coverImage__placeholder a-mediumText a-lightText -opacity-60 aligned-center fullHeight fullWidth justify-center">
                                {t('general.heroImage')}
                            </div>
                        )}
                        {imageSrc && (
                            <Cropper
                                crop={crop}
                                zoom={zoom}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                image={imageSrc}
                                aspect={4}
                                showGrid={false}
                                objectFit="horizontal-cover"
                                zoomWithScroll={false}
                            />
                        )}
                    </div>
                </div>
                <div className="coverImage__bottomContainer fullWidth">
                    <div className="-zoomContainer a-inputRange -mb50">
                        <h5 className="-mb25">{t('general.zoom')}</h5>
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value)
                            }}
                            className="zoom-range"
                            style={{
                                background: `linear-gradient(to right, ${COLORS.LIGHT_BLUE} 0%, ${COLORS.LIGHT_BLUE} ${progress}%, white ${progress}%, white 100%)`,
                            }}
                        />
                    </div>

                    <div className="-buttonsContainer space-between flex-wrap">
                        <div className="coverImage__rightButtonContainer">
                            <div className="-button aligned-center justify-center flex-wrap -mb20">
                                <Button
                                    label={'button.delete'}
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={() => deleteCoverPhoto()}
                                />
                            </div>

                            <div className="-button -mb20">
                                <Button
                                    label={'button.cancel'}
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={() => setOpen(false)}
                                />
                            </div>
                        </div>
                        <div className="coverImage__rightButtonContainer">
                            <div>
                                <input
                                    type="file"
                                    onChange={onFileChange}
                                    accept="image/*"
                                    hidden={true}
                                    ref={inputFile}
                                />
                                <div className="-button -mb20">
                                    <Button
                                        label={'button.uploadNewPhoto'}
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        onClick={() => {
                                            inputFile.current.click()
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="-button -mb20">
                                <Button
                                    label={'button.apply'}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={handleApply}
                                />
                            </div>
                        </div>
                    </div>
                    {loading && <Loader />}
                </div>
            </Modal>
        </div>
    )
}

CoverImageUploadFields.propTypes = {
    name: PropTypes.string.isRequired,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    title: PropTypes.string,
    handleChildSubmit: PropTypes.func,
    setSubmitting: PropTypes.func,
    fileCategory: PropTypes.string.isRequired,
}

export default CoverImageUploadFields
