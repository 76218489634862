import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import { getValueByKey } from 'utils/dataFormatters'

import ListOfStatues from 'components/ListOfStatuses'

const About = ({ user }) => {
    const t = useTranslate()

    const { languages, regions, positions } = user

    return (
        <Fragment>
            {positions && (
                <Fragment>
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.roles')}
                    </span>
                    <ListOfStatues statuses={positions} statusColor="-blue" />
                </Fragment>
            )}
            {languages && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.languages')}
                    </span>
                    <ListOfStatues
                        statuses={languages}
                        statusColor="-pink"
                        customStatusValue={(item) =>
                            `${item.language.data.attributes.name} - ${item.languageProficiency.data.attributes.name}`
                        }
                    />
                </div>
            )}
            {regions?.length !== 0 && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.preferredRegionsOfWork')}
                    </span>
                    <ListOfStatues statuses={regions} statusColor="-green" />
                </div>
            )}
        </Fragment>
    )
}

About.propTypes = {
    user: PropTypes.object,
}

export default About
