import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ICONS from 'constants/icons'

import IconButton from 'components/IconButton'

const ModalTitle = ({
    title,
    noTranslate = false,
    closeButton,
    closeModal,
    setOpen,
}) => {
    const t = useTranslate()
    //NOTE: Empty element is used for better alignment of the elements
    const emptyElement = <span></span>

    return (
        <div className="_w -header aligned-start space-between flex-no-wrap -gap10">
            {emptyElement}
            <h3 className={'justify-center centered-text'}>
                {noTranslate ? title : t(title)}
            </h3>
            {setOpen || closeButton ? (
                <IconButton
                    icon={ICONS.CLEAR}
                    onClick={closeButton || closeModal}
                />
            ) : (
                emptyElement
            )}
        </div>
    )
}

ModalTitle.propTypes = {
    title: PropTypes.string.isRequired,
    noTranslate: PropTypes.bool,
    closeButton: PropTypes.func,
    closeModal: PropTypes.func,
    setOpen: PropTypes.func,
}

export default ModalTitle
