const ICONS = {
    HOME_LOGO_LIGHT: 'home_logo_light',
    HOME_LOGO_DARK: 'home_logo_dark',
    CHEVRON_DOWN_SQUARE: 'chevron_down_square',
    CHEVRON_UP_SQUARE: 'chevron_up_square',
    CHEVRON_RIGHT_SQUARE: 'chevron_right_square',
    CHEVRON_LEFT_SQUARE: 'chevron_left_square',
    CHEVRON_DOWN_CIRCLE: 'chevron_down_circle',
    CHEVRON_UP_CIRCLE: 'chevron_up_circle',
    CHEVRON_RIGHT_CIRCLE: 'chevron_right_circle',
    CHEVRON_LEFT_CIRCLE: 'chevron_left_circle',
    CHEVRON_DOWN: 'chevron_down',
    CHEVRON_UP: 'chevron_up',
    CHEVRON_RIGHT: 'chevron_right',
    CHEVRON_LEFT: 'chevron_left',
    DOUBLE_CHEVRON_LEFT: 'double_chevron_left',
    ARROW_DOWN_SQUARE: 'arrow_down_square',
    ARROW_UP_SQUARE: 'arrow_up_square',
    ARROW_RIGHT_SQUARE: 'arrow_right_square',
    ARROW_LEFT_SQUARE: 'arrow_left_square',
    ARROW_DOWN_CIRCLE: 'arrow_down_circle',
    ARROW_UP_CIRCLE: 'arrow_up_circle',
    ARROW_RIGHT_CIRCLE: 'arrow_right_circle',
    ARROW_LEFT_CIRCLE: 'arrow_left_circle',
    ARROW_DOWN_RIGHT_CIRCLE: 'arrow_down_right_circle',
    ARROW_DOWN_LEFT_CIRCLE: 'arrow_down_left_circle',
    ARROW_UP_RIGHT_CIRCLE: 'arrow_up_right_circle',
    ARROW_UP_LEFT_CIRCLE: 'arrow_up_left_circle',
    ARROW_DOWN: 'arrow_down',
    ARROW_UP: 'arrow_up',
    ARROW_RIGHT: 'arrow_right',
    ARROW_LEFT: 'arrow_left',
    ARROW_DOWN_RIGHT: 'arrow_down_right',
    ARROW_DOWN_LEFT: 'arrow_down_left',
    ARROW_UP_RIGHT: 'arrow_up_right',
    ARROW_UP_LEFT: 'arrow_up_left',
    SWAP_VERTICAL: 'swap_vertical',
    SWAP_HORIZONTAL: 'swap-horizontal',
    RELOAD: 'reload',
    EXPAND: 'expand',
    COLLAPSE: 'collapse',
    DOTS: 'dots',
    WHITE_CHECKMARK: 'white_checkmark',
    DARK_CHECKMARK: 'dark_checkmark',
    CLOSE: 'close',
    CLOSE_CIRCLE: 'close_circle',
    PLUS: 'plus',
    MAIL: 'mail',
    TIME: 'time',
    FILTER: 'filter',
    LOCATION: 'location',
    PHONE: 'phone',
    UPLOAD: 'upload',
    USER: 'user',
    EDIT: 'edit',
    COPY: 'copy',
    DELETE: 'delete',
    NOTIFICATIONS: 'notifications',
    NOTIFICATIONS_NO_CIRCLE: 'notifications_no_circle',
    INFO_CIRCLE: 'info_circle',
    SEARCH: 'search',
    HOMEPAGE: 'homepage',
    HOMEPAGE_BREADCRUMB: 'homepage_breadcrumb',
    DIVE_PROJECTS: 'dive_projects',
    DIVE_PROJECTS_OUTLINE: 'dive_projects_outline',
    GALLERY: 'gallery',
    CALENDAR: 'calendar',
    FAQ: 'faq',
    LEGAL: 'legal',
    LOGOUT: 'logout',
    SETTINGS: 'settings',
    GOOGLE: 'google',
    LINKEDIN: 'linkedin',
    HOME_PAGE: 'home_page',
    STEP_ICON: 'step_icon',
    CHECKMARK_ICON: 'checkmark_icon',
    SORT_ICON: 'sort_icon',
    SORT_DESCENDING: 'sort_descending',
    LINE: 'line',
    DOCUMENT: 'document',
    FOOTER_MAIL: 'footer_mail',
    FOOTER_PHONE: 'footer_phone',
    FOOTER_LOCATION: 'footer_location',
    ALERT_ERROR: 'alert_error',
    ALERT_WARNING: 'alert_warning',
    ALERT_SUCCESS: 'alert_success',
    ALERT_NOTIFICATION: 'alert_notification',
    OFFICE: 'office',
    HOUSE: 'house',
    TOOLS: 'tools',
    BADGE: 'badge',
    SCHOOL: 'school',
    BOOK: 'book',
    BADGE_CIRCLE: 'badge_circle',
    SHARE: 'share',
    BANNER_PLACEHOLDER: 'banner_placeholder',
    GLOBE: 'globe',
    FAVORITES: 'favorites',
    VERIFICATION: 'verification',
    EDIT_USER_PROFILE: 'edit_user_profile',
    WEBSITE: 'website',
    SHOW_PASSWORD: 'show_password',
    HIDE_PASSWORD: 'hide_password',
    GALLERY_PHOTO: 'gallery_photo',
    CHECKMARK_ICON_ROUNDED: 'checkmark_icon_rounded',
    CHECKMARK_ICON_ROUNDED_FILL: 'checkmark_icon_rounded_fill',
    DOWNLOAD: 'download',
    PERSONAL_INFO: 'personal_info',
    DIVE_RECORDS: 'dive_records',
    MEDICAL_INFO: 'medical_info',
    TRAVEL_DOCUMENTATION: 'travel_documentation',
    TRAINING_RECORD: 'training_record',
    PERSONAL_EQUIPMENT: 'personal_equipment',
    PAST_EMPLOYMENT: 'past_employment',
    SKILLS: 'skills',
    ALERT_INFO: 'alert_info',
    NOTIFICATION_DANGER: 'notification_danger',
    APPLICATION_ACCEPTED: 'application_accepted',
    APPLICATION_REJECTED: 'application_rejected',
    CAMERA: 'camera',
    PHOTO: 'photo',
    ABOUT_COMPANY: 'about_company',
    BUILDING: 'building',
    GRANT_ACCESS: 'grant_access',
    DENY_ACCESS: 'deny_access',
    CHANGE_ACCESS: 'change_access',
    VERIFICATION_PROFILE: 'verification_profile',
    WALLET: 'wallet',
    BILLING: 'billing',
    CHAT: 'chat',
    CHAT_WITH_ICON: 'chat_with_icon',
    PIN: 'pin',
    UNITS: 'units',
    DATA_ACCESS: 'data_access',
    LINK: 'link',
    CHECKMARK_CIRCLE: 'checkmark_circle',
    VERIFICATION_SHARE: 'verification_share',
    HELP_AND_INFO: 'help_and_info',
    EXPLORE: 'explore',
    RELEASE_NOTES: 'release_notes',
    LEVEL_0: 'level_0',
    LEVEL_1: 'level_1',
    LEVEL_2: 'level_2',
    LEVEL_3: 'level_3',
    LEVEL_4: 'level_4',
    LEVEL_5: 'level_5',
    USER_DIVER: 'user_diver',
    USER_SUPERVISOR: 'user_supervisor',
    SAVE: 'save',
    ROUNDED_EDIT: 'rounded_edit',
    SETUP: 'setup',
    LEFT_DOUBLE_ARROW: 'left_double_arrow',
    LEFT_ARROW: 'left_arrow',
    RIGHT_DOUBLE_ARROW: 'right_double_arrow',
    RIGHT_ARROW: 'right_arrow',
    CLEAR: 'clear',
    HELP_AND_INFO_DESCRIPTION: 'help_and_info_description',
    DIVER: 'diver',
    CLOCK: 'clock',
    EXPORT_CV: 'export_cv',
    GALLERY_SIDEBAR: 'gallery_sidebar',
    ACCOUNT_MANAGEMENT: 'account_management',
    NO_DATA: 'no_data',
    BELL: 'bell',
    SEARCH_ICON: 'search_icon',
    USERS: 'users',
    GENERAL_PREFERENCES: 'general_preferences',
    SIGN_IN_AND_SECURITY: 'sign_in_and_security',
    NOTIFICATION_BELL: 'notification_bell',
    SETTINGS_AND_PRIVACY: 'settings_and_privacy',
    LOCK: 'lock',
    POSITION_DIVER: 'position_diver',
    POSITION_DIVING_SUPERVISOR: 'position_diving_supervisor',
    POSITION_EQUIPMENT_TECHNICIAN: 'position_equipment_technician',
    POSITION_DIVING_MEDIC: 'position_diving_medic',
    POSITION_LIFE_SUPPORT: 'position_life_support',
    ADCI: 'adci',
    SHIELD: 'shield',
    DATA_PROTECTION: 'data_protection',
    UI_UX_IMPROVEMENTS: 'ui_ux_improvements',
    MEDAL: 'medal',
    GALLERY_IMPROVEMENTS: 'gallery_improvements',
    UPCOMING_FEATURES: 'upcoming_features',
    PROJECT_RECRUITMENT: 'project_recruitment',
    VERIFICATION_OUTLINE: 'verification_outline',
    MAP_ICON: 'map_icon',
    LISTING_AND_SEARCH: 'listing_and_search',
    LOADER_ICON: 'loader_icon',
    BUG_FIXES_AND_PERFORMANCE: 'bug_fixes_and_performance',
    VIDEO: 'video',
    APPLICATION_CANDIDATE: 'application_candidate',
    LIKE: 'like',
    DISLIKE: 'dislike',
    OCCUPATIONAL_DIVING_PERSONNEL: 'occupational_diving_personnel',
    MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL:
        'marine_mobile_offshore_unit_personnel',
    ROV_PERSONNEL: 'rov_personnel',
    OFFSHORE_SURVEY_PERSONNEL: 'offshore_survey_personnel',
    PROJECT_MISSION_PERSONNEL: 'project-mission_personnel',
    PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL:
        'project_operations_management_personnel',
    POSITION_DIVE_SUPERINTENDENT: 'position_dive_superintendent',
    POSITION_OFFSHORE_MANAGER_DIVING: 'position_offshore_manager_diving',
    PRO: 'pro',
    CHECKMARK_SMALL: 'checkmark_small',
    SUPPORT: 'support',
    SUPPORT_PAGE_ICON: 'support_page_icon',
    USER_MANAGEMENT: 'user_management',
    BLOCK: 'block',
    CHECKN_DEPTH: 'chekn_depth',
    QUESTION_MARK_ICON: 'question_mark_icon',
    CODE: 'code',
    OFFLINE_MODE: 'offline_mode',
    CALL_INFO: 'call_info',
    FEEDBACK: 'feedback',
    CLOCK_CIRCLE: 'clock_circle',
    CHECKMARK_VERIFICATION: 'checkmark_verification',
    QR: 'qr',
    PREVIEW: 'preview',
    DOWNLOAD_SQUARE: 'download_square',
    PAY_PAL: 'pay_pal',
    CARD: 'card',
    ZOOM_WITH_CHECK: 'zoom_with_check',
    QR_CODE: 'qr_code',
    MOBILE_PHONE: 'mobile_phone',
    STAR: 'star',
    STAR_FILL: 'star_fill',
    SERVICE_CONTRACTOR_ORGANIZATION: 'service_contractor_organization',
    CLIENT_ORGANIZATION: 'client_organization',
    TRADE_ASSOCIATION_AND_CERTIFICATE_ORGANIZATION:
        'trade_association_and_certificate_organization',
    SCIENTIFIC_ACADEMIC_INSTITUTE: 'scientific_academic_institute',
    TRAINING_INSTITUTION: 'training_institution',
    PUBLIC_SAFETY_ORGANIZATION: 'public_safety_organization',
    STAFFING_AGENCY: 'staffing_agency',
    OTHER_ROLES: 'other_roles',
}
export default ICONS
