import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getProfileTabs } from './constants/tabs'
import { BUTTON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

import TabNavigation from 'components/tabs/TabNavigation'
import UserInfo from './components/UserInfo'
import Button from 'components/Button'
import ICONS from 'constants/icons'
import InviteDiverToProject from 'components/InviteDiverToProject'

const DiverInformation = ({ myProfile, userID, user, setOpen }) => {
    const t = useTranslate()

    const { isContractor, isClientOrganization } = useContext(
        CurrentUserContext
    )

    const [inviteDiverToProject, setInviteDiverToProject] = useState(null)

    const handleOpenInviteDiverToProject = () => {
        setInviteDiverToProject({
            ...user,
            fullName: `${user.firstName} ${user.lastName}`,
        })
    }

    const handleCloseInviteDiverToProject = () => {
        setInviteDiverToProject(null)
    }

    return (
        <div className="diverInformation">
            <div className="-box -mb20">
                <UserInfo
                    user={user}
                    myProfile={myProfile}
                    userID={userID}
                    setOpen={setOpen}
                />
            </div>
            {isContractor && !isClientOrganization && !myProfile && (
                <div className="-mb20">
                    <Button
                        label="button.inviteToProject"
                        icon={ICONS.PLUS}
                        iconColor={COLORS.WHITE}
                        buttonSize={BUTTON_SIZE.SMALL}
                        onClick={handleOpenInviteDiverToProject}
                    />
                </div>
            )}
            <div className="-box">
                <p className="-mb20 a-bodyTextMedium">
                    {t('general.personalInformation')}
                </p>

                <TabNavigation
                    tabs={getProfileTabs(user, myProfile)}
                    tabQueryParam="tab"
                    columnView
                    scrollToTarget={false}
                />
            </div>
            {!!inviteDiverToProject && (
                <InviteDiverToProject
                    diver={inviteDiverToProject}
                    handleClose={handleCloseInviteDiverToProject}
                />
            )}
        </div>
    )
}

DiverInformation.propTypes = {
    myProfile: PropTypes.bool,
    userID: PropTypes.number,
    user: PropTypes.object,
    setOpen: PropTypes.func,
}

DiverInformation.defaultProps = {
    myProfile: false,
}

export default DiverInformation
