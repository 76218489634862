import React from 'react'
import { useTranslate } from 'react-polyglot'
import QRCode from 'react-qr-code'

import { BASE_URL } from 'utils/axiosClient'

import ROUTES from 'constants/routes'

import Modal from 'components/Modal'

const CertificateQrCode = ({ certificate, setCertificate }) => {
    const t = useTranslate()

    const hash = certificate.certificateHash

    return (
        <Modal
            open={true}
            setOpen={() => {
                setCertificate(null)
            }}
            title="general.scanQrCode"
            smallModal
        >
            <div className="_w">
                <div className="_12">
                    <div className="a-mediumText a-lightText -mb20 justify-center">
                        {t('general.scanQrCodeToCheckCertificate')}
                    </div>
                </div>
                <div className="_12">
                    <div className="qrCodeBox -mt10 -mb30">
                        <QRCode
                            size={180}
                            value={`${BASE_URL}${ROUTES.CERTIFICATE_QR}/${hash}`}
                            viewBox={`0 0 210 210`}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CertificateQrCode
