import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { getValueByKey } from 'utils/dataFormatters'

const CellItem = ({ headerKey, headerItem, item }) => {
    const t = useTranslate()

    const { CellComponent, formatter } = headerItem

    const value = getValueByKey(headerKey, item)

    if (CellComponent)
        return <CellComponent {...headerItem} value={value} item={item} />

    if (formatter) {
        return formatter(item, t, value) || '/'
    }
    return value || '/'
}

CellItem.propTypes = {
    headerKey: PropTypes.string,
    headerItem: PropTypes.object,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CellItem
