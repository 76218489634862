import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
} from 'constants/enums'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Modal from 'components/Modal'
import SelectField from 'components/formFields/SelectField'
import * as Yup from 'yup'
import axios, { POST } from 'utils/axiosClient'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

const DiveTeamStatusUpdate = ({
    projectPositionId,
    updateListFunc,
    disabled = false,
    diveInviteStatuses,
}) => {
    const t = useTranslate()

    const [open, setOpen] = useState(false)

    const initialValues = {
        status: null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        status: Yup.object().required(requiredMessage),
    })

    const handleSubmit = async (formData) => {
        await axios(POST, '/diver_invite/bulk_update_status', {
            projectPositionId,
            statusId: formData.status.id,
        })

        updateListFunc()
        setOpen(false)
    }

    if (!diveInviteStatuses) return null

    return (
        <div>
            <Button
                icon={ICONS.SETUP}
                iconSize={ICON_SIZE.SIZE20}
                iconColor={COLORS.LIGHT_BLUE}
                onClick={() => setOpen(true)}
                btnClass={BUTTON_STATUS.SECONDARY}
                additionalClasses="update-all-statuses"
                buttonSize={BUTTON_SIZE.LARGE}
                tooltip={'general.changeStatusForAllCandidates'}
                disabled={disabled}
            ></Button>

            {open && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                title="general.updateStatusForAllCandidates"
                                smallModalWithDropdowns
                                smallModal
                                buttons={{
                                    nextBtn: {
                                        label: 'button.continue',
                                    },
                                }}
                            >
                                <div className="_wr">
                                    <div className="_w">
                                        <div className="-mb20 _12 a-lightText a-mediumText">
                                            {t(
                                                'general.diverInviteStatusChange'
                                            )}
                                        </div>

                                        <div className="_12">
                                            <SelectField
                                                name="status"
                                                label="form.label.diverInviteStatus"
                                                placeholder="form.placeholder.diverInviteStatus"
                                                defaultOptions={
                                                    diveInviteStatuses
                                                }
                                                searchable
                                                required
                                            />
                                        </div>
                                    </div>
                                    {isSubmitting && <Loader />}
                                </div>
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    )
}

DiveTeamStatusUpdate.propTypes = {
    projectPositionId: PropTypes.number,
    updateListFunc: PropTypes.func,
    diveInviteStatuses: PropTypes.array,
}

export default DiveTeamStatusUpdate
