import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { createEntityService } from 'services/entity.service'

import { FeedbackModalContext } from 'contexts/FeedbackModalContext'
import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import Modal from './Modal'
import Loader from './Loader'
import { SelectField, TextAreaField } from './formFields'
import FileUploadField from './formFields/FileUploadField'
import Separator from './Separator'

const FeedbackModal = () => {
    const t = useTranslate()

    const {
        feedbackModal: { showModal },
        closeFeedbackModal,
    } = useContext(FeedbackModalContext)

    const { setAlert } = useContext(AlertContext)

    if (!showModal) return null

    const initialValues = {
        feedbackCategory: null,
        description: '',
        attachments: [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        feedbackCategory: Yup.object().required(requiredMessage),
        description: Yup.string().required(requiredMessage),
        attachments: Yup.array(),
    })

    const handleSubmit = async (formData) => {
        try {
            await createEntityService(ENTITIES.FEEDBACK, formData)
            setAlert(t('feedback.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            closeFeedbackModal()
        }
    }

    const handleCancel = () => {
        closeFeedbackModal()
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting, handleSubmit }) => (
                <Form className="_12">
                    <Modal
                        open={true}
                        setOpen={handleCancel}
                        buttons={{
                            prevBtn: {
                                label: 'button.cancel',
                                handleClick: handleCancel,
                            },
                            nextBtn: {
                                label: 'feedback.submit',
                                handleClick: handleSubmit,
                            },
                        }}
                        closeButton={handleCancel}
                        title="feedback.modalTitle"
                        closeOnClickOutside={false}
                    >
                        <div className="_12 -mb5">
                            <span className="a-bodyTextMedium">
                                {t('feedback.title')}
                            </span>
                        </div>
                        <div className="_12 -mb20">
                            <div className="column fullWidth">
                                <span className="fullWidth a-lightText a-mediumText">
                                    {t('feedback.description')}
                                </span>
                            </div>
                        </div>

                        <div className="_12 ">
                            <span className="a-bodyTextMedium">
                                {t('feedback.feedbackCategory')}
                            </span>
                            <div className="-mt10">
                                <SelectField
                                    name="feedbackCategory"
                                    label="feedback.label.feedbackCategory"
                                    placeholder="feedback.placeholder.feedbackCategory"
                                    entityType={ENTITIES.FEEDBACK_CATEGORY}
                                    required
                                />
                            </div>
                        </div>
                        <div className="_12 ">
                            <span className="a-bodyTextMedium">
                                {t('feedback.describe')}
                            </span>
                            <div className="-mt10">
                                <TextAreaField
                                    name="description"
                                    label="feedback.label.description"
                                    placeholder="feedback.placeholder.description"
                                    flexibleTextarea
                                    required
                                />
                            </div>
                        </div>
                        <Separator />
                        <div className="_12  -mt10 -mb10">
                            <span className="a-bodyTextMedium">
                                {t('feedback.attachment')}
                            </span>
                        </div>
                        <div className="-pb20 fullWidth">
                            <FileUploadField
                                name="attachments"
                                label="feedback.label.attachments"
                                attachmentNote="feedback.attachment_note"
                                fileCategory={
                                    FILE_CATEGORIES.FEEDBACK_ATTACHMENTS
                                }
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default FeedbackModal
