import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import { formatUrl } from 'utils/jsonApiFormatters'

import { BUTTON_SIZE, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Icon from 'components/Icon'

const CompleteProfile = ({
    title,
    name,
    description,
    showProgress,
    stepsToComplete,
    completedSteps,
    allSteps,
    profilePage,
    currentUser,
    profileHash,
}) => {
    const navigate = useNavigate()
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    const [step, setStep] = useState(0)

    let currentStep = stepsToComplete[step]

    const handleChangeStep = (index) => {
        if (isSmallScreen) {
            setStep(step + index < stepsToComplete.length ? step + index : 0)
        } else {
            setStep(index + 1 < stepsToComplete.length ? index + 1 : 0)
        }
    }

    const handleUpdateProfile = (index) => {
        index && (currentStep = stepsToComplete[index])
        navigate(
            formatUrl(
                `${currentStep.route}/${
                    currentUser.profileHash || profileHash
                }`,
                currentStep.params
            ),
            {
                state: { [currentStep.key]: true },
            }
        )

        //scroll Tab to view
        const activeTabComponent = document.querySelectorAll('.-tabContentBox')
        const targetScrollPosition = activeTabComponent[0]?.offsetTop + 20
        window.scrollTo({
            top: targetScrollPosition,
            behavior: 'smooth',
        })
    }

    if (!stepsToComplete.length) return null

    return (
        <div
            className={`m-boxes__white -mb20 ${
                profilePage ? '-profilePage' : ''
            } `}
        >
            <div className="column">
                {title && <span className="a-bodyTextMedium">{t(title)}</span>}
                {name && (
                    <span className="a-bodyTextMedium breakWord">{`${t(
                        'general.welcome'
                    )}, ${name}!`}</span>
                )}
                {description && (
                    <p className="-mt10 a-mediumText a-lightText -opacity-60 -mb15">
                        {t(description)}
                    </p>
                )}
                {showProgress && (
                    <div className="a-progressBar -withLabel -mb15">
                        <div className="-wrapper">
                            <div className="-bar">
                                <div
                                    style={{
                                        width: `${
                                            (completedSteps.length * 100) /
                                            allSteps.length
                                        }%`,
                                    }}
                                    className="-progress"
                                ></div>
                            </div>
                        </div>
                        <span className="a-captionsTextRegular a-lightText -opacity-60 -label">
                            {completedSteps.length}/{allSteps.length}{' '}
                            {t('general.complete')}
                        </span>
                    </div>
                )}
                <div
                    className={`-slider ${
                        stepsToComplete.length !== 0 && !isSmallScreen
                            ? '-active'
                            : ''
                    } ${stepsToComplete.length <= 1 ? '-noMargin' : ''}`}
                >
                    {stepsToComplete.map((item, index) => {
                        return (
                            <div
                                className={`m-completeProfile space-between aligned-center ${
                                    item == currentStep ? '-active' : ''
                                }`}
                                key={item.key}
                                onClick={
                                    isSmallScreen
                                        ? () => {
                                              handleUpdateProfile(index)
                                          }
                                        : () => {}
                                }
                                onMouseDown={() =>
                                    isSmallScreen && handleChangeStep(index)
                                }
                            >
                                <div className="m-completeProfile__label aligned-center">
                                    {item?.icon && (
                                        <Icon
                                            name={item?.icon}
                                            color={COLORS.LIGHT_BLUE}
                                            size={ICON_SIZE.SIZE20}
                                        />
                                    )}
                                    <div className="column">
                                        <span className="a-mediumTextSemiBold">
                                            {t(item?.message)}
                                        </span>
                                        {item.additionalMessage && (
                                            <span className="a-mediumText a-lightText -opacity-60 -mt5">
                                                {t(item.additionalMessage)}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {!isSmallScreen && (
                                    <Button
                                        label="button.updateProfile"
                                        onClick={() =>
                                            handleUpdateProfile(index)
                                        }
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                    />
                                )}
                                {isSmallScreen && (
                                    <Icon
                                        name={ICONS.CHEVRON_RIGHT}
                                        color={COLORS.DARK_BLUE}
                                        size={ICON_SIZE.SIZE20}
                                    />
                                )}
                                {stepsToComplete.length > 1 && (
                                    <span
                                        className="m-completeProfile__next"
                                        onClick={() => handleChangeStep(index)}
                                    >
                                        <Icon
                                            name={ICONS.CHEVRON_RIGHT}
                                            color={COLORS.DARK_BLUE}
                                            size={ICON_SIZE.SIZE20}
                                        />
                                    </span>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

CompleteProfile.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    showProgress: PropTypes.bool,
    stepsToComplete: PropTypes.array,
    completedSteps: PropTypes.array,
    allSteps: PropTypes.array,
    entityId: PropTypes.number,
    profilePage: PropTypes.bool,
}

CompleteProfile.defaultProps = {
    showProgress: false,
}

export default CompleteProfile
