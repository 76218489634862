import { useContext } from 'react'

import ROUTES from 'constants/routes'
import { ALL_ROLES, COMPANY_ROLES, ROLES } from 'constants/enums'
import ICONS from 'constants/icons'
import { ENTITY_MANAGEMENT_GROUPS } from 'constants/managementEntities'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

const { ROLE_SUPER_ADMIN, ROLE_DIVER } = ROLES

export const ADMIN_MENU_ITEMS = [
    {
        key: 'user_management',
        label: 'menuItems.userManagement',
        mainLabel: true,
        roles: [ROLE_SUPER_ADMIN],
    },
    {
        key: 'individual-account-management',
        label: 'menuItems.individualAccountManagement',
        to: ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN],
    },
    {
        key: 'company-account-management',
        label: 'menuItems.companyAccountManagement',
        to: ROUTES.COMPANY_ACCOUNT_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN],
    },
    {
        key: 'separator1',
        roles: ALL_ROLES,
        separator: true,
    },
    {
        key: 'list_management',
        label: 'menuItems.listManagement',
        mainLabel: true,
        roles: [ROLE_SUPER_ADMIN],
    },
    ...ENTITY_MANAGEMENT_GROUPS,
    {
        key: 'separator2',
        roles: ALL_ROLES,
        separator: true,
    },
    {
        key: 'call-center-management',
        label: 'menuItems.callCenter',
        to: ROUTES.CALL_CENTER,
        roles: [ROLE_SUPER_ADMIN],
    },
    {
        key: 'terms-and-conditions',
        label: 'menuItems.termsAndConditions',
        to: ROUTES.TERMS_AND_CONDITIONS,
        roles: [ROLE_SUPER_ADMIN],
    },
]

export const USER_MENU_ITEMS = [
    {
        key: 'home',
        label: 'menuItems.homePage',
        to: ROUTES.HOME,
        icon: ICONS.HOMEPAGE,
        roles: ALL_ROLES,
        showInHeader: true,
    },
    {
        key: 'dive-projects',
        label: 'menuItems.diveProjects',
        to: ROUTES.MY_DIVE_PROJECTS,
        icon: ICONS.DIVE_PROJECTS_OUTLINE,
        roles: ALL_ROLES,
        showInHeader: true,
    },
    {
        key: 'dive-records',
        label: 'menuItems.myRecords',
        to: ROUTES.DIVE_RECORDS,
        icon: ICONS.DIVE_RECORDS,
        roles: [ROLE_DIVER],
        showInHeader: true,
    },
    // {
    //     key: 'Analytics',
    //     label: 'menuItems.analytics',
    //     to: ROUTES.ANALYTICS,
    //     icon: 'dive_projects_outline',
    //     roles: COMPANY_ROLES,
    //     showInHeader: true,
    // },
    {
        key: 'separator1',
        roles: ALL_ROLES,
        separator: true,
    },
    {
        key: 'settings',
        label: 'menuItems.settings',
        to: ROUTES.SETTINGS,
        icon: ICONS.SETTINGS,
        roles: ALL_ROLES,
    },
    {
        key: 'helpAndInfo',
        label: 'menuItems.helpAndInfo',
        to: ROUTES.HELP_AND_INFO,
        icon: ICONS.HELP_AND_INFO,
        roles: ALL_ROLES,
    },
    {
        key: 'checkCertificates',
        label: 'menuItems.checkCertificates',
        to: ROUTES.CHECK_CERTIFICATES,
        icon: ICONS.VERIFICATION_PROFILE,
        roles: COMPANY_ROLES,
        showInHeader: true,
    },
]

const useMenuItems = (menuItems) => {
    const { currentUser } = useContext(CurrentUserContext)

    return menuItems.filter(
        ({ roles = ALL_ROLES, customCondition = (user) => true }) =>
            roles.some((role) => role.name === currentUser.role.name) &&
            customCondition(currentUser)
    )
}

export default useMenuItems
