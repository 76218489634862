import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Select from 'components/Select'
import FieldError from 'components/FieldError'
import IconButton from './IconButton'

const SelectGroup = ({
    error,
    title,
    value,
    setValue,
    setTouched,
    description,
    required,
    fieldsName,
    fieldsDefaultOptions,
    fieldsPlaceholder,
    fieldsEntityType,
    fieldsParams,
    fieldsSearchable,
    fieldsIsJSONAPI,
    fieldsApiUrl,
    fieldsDisabled,
    fieldsDisplayAttribute,
    fieldsSearchAttribute,
    secondFieldRelation,
    fieldsRequired,
    fieldsValueType,
    buttonLabel,
    reload,
    disableAddOnlyForBoth,
    fieldsShowLabel,
    fieldsShowPlaceholder,
    dropup,
}) => {
    const t = useTranslate()

    const [firstField, setFirstField] = useState(null)
    const [secondField, setSecondField] = useState(null)

    const [firstFieldName, secondFieldName] = fieldsName

    const canAdd = () => {
        return disableAddOnlyForBoth
            ? value.some(
                  (item) =>
                      item[firstFieldName].id === firstField.id &&
                      item[secondFieldName].id === secondField.id
              )
            : value.some((item) => item[firstFieldName].id === firstField.id)
    }

    const handleAdd = () => {
        const containsFirstValue = canAdd()

        !containsFirstValue &&
            setValue([
                ...value,
                {
                    [firstFieldName]: firstField,
                    [secondFieldName]: secondField,
                },
            ])
        setFirstField(null)
        setSecondField(null)
    }

    const handleRemove = (e, index) => {
        e.stopPropagation()
        const newValue = [...value]
        newValue.splice(index, 1)
        setValue(newValue)
    }

    const handleChangeFirstField = (value) => {
        setFirstField(value)
        setSecondField(null)
    }

    const handleTouch = () => setTouched(true)

    return (
        <div className="m-selectGroup -multiple">
            {(title || description) && (
                <div className="m-selectGroup__title">
                    {title && (
                        <span className="a-bodyTextRegular -mb5">
                            {t(title)}{' '}
                            {required && <span className="redStar">*</span>}
                        </span>
                    )}
                    {description && (
                        <span className="a-mediumText a-lightText">
                            {t(description)}
                        </span>
                    )}
                </div>
            )}
            <div className="m-selectGroup__field -singleSelect">
                <div className="-firstField">
                    <Select
                        name={fieldsName[0]}
                        value={firstField}
                        setValue={handleChangeFirstField}
                        placeholder={fieldsPlaceholder[0]}
                        defaultOptions={fieldsDefaultOptions[0]}
                        entityType={fieldsEntityType[0]}
                        params={fieldsParams[0]}
                        searchable={fieldsSearchable[0]}
                        isJSONAPI={fieldsIsJSONAPI[0]}
                        apiUrl={fieldsApiUrl[0]}
                        disabled={fieldsDisabled[0]}
                        displayAttribute={fieldsDisplayAttribute[0]}
                        searchAttribute={fieldsSearchAttribute[0]}
                        valueType={fieldsValueType[0]}
                        required={fieldsRequired[0]}
                        showLabel={fieldsShowLabel[0]}
                        showPlaceholder={fieldsShowPlaceholder[0]}
                        onBlur={handleTouch}
                        error={error}
                        customError={true}
                        reload={reload[0]}
                        dropup={dropup}
                    />
                </div>
                <div className="aligned-end -secondFieldAndButton">
                    <Select
                        name={fieldsName[1]}
                        value={secondField}
                        setValue={setSecondField}
                        placeholder={fieldsPlaceholder[1]}
                        defaultOptions={fieldsDefaultOptions[1]}
                        entityType={fieldsEntityType[1]}
                        params={
                            secondFieldRelation
                                ? {
                                      ...fieldsParams[1],
                                      [secondFieldRelation]: firstField?.id,
                                  }
                                : fieldsParams[1]
                        }
                        searchable={fieldsSearchable[1]}
                        isJSONAPI={fieldsIsJSONAPI[1]}
                        apiUrl={fieldsApiUrl[1]}
                        disabled={fieldsDisabled[1]}
                        displayAttribute={fieldsDisplayAttribute[1]}
                        searchAttribute={fieldsSearchAttribute[1]}
                        valueType={fieldsValueType[1]}
                        required={fieldsRequired[1]}
                        showLabel={fieldsShowLabel[1]}
                        showPlaceholder={fieldsShowPlaceholder[1]}
                        onBlur={handleTouch}
                        error={error}
                        customError={true}
                        reload={secondFieldRelation ? firstField : reload[1]}
                        dropup={dropup}
                    />
                    <Button
                        btnClass={BUTTON_STATUS.SECONDARY}
                        type={BUTTON_TYPE.BUTTON}
                        label={buttonLabel}
                        onClick={handleAdd}
                        disabled={!firstField || !secondField || canAdd()}
                    />
                </div>
            </div>
            {value.length > 0 && (
                <div className="m-multiselectGroup__items">
                    {value.map((item, index) => {
                        return (
                            <div
                                className="m-multiselectGroup__item"
                                key={`${item[firstFieldName].id}-${item[secondFieldName].id}`}
                            >
                                <span className="label a-mediumText">
                                    {item[firstFieldName].name +
                                        '-' +
                                        item[secondFieldName].name}
                                </span>
                                <IconButton
                                    icon={ICONS.CLEAR}
                                    onClick={(e) => handleRemove(e, index)}
                                    iconSize={ICON_SIZE.SIZE16}
                                />
                            </div>
                        )
                    })}
                </div>
            )}
            {error && <FieldError error={error} />}
        </div>
    )
}

export const SelectGroupMainPropTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    fieldsName: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDefaultOptions: PropTypes.arrayOf(PropTypes.array),
    fieldsPlaceholder: PropTypes.arrayOf(PropTypes.string),
    fieldsEntityType: PropTypes.arrayOf(PropTypes.string),
    fieldsParams: PropTypes.arrayOf(PropTypes.object),
    fieldsSearchable: PropTypes.arrayOf(PropTypes.bool),
    fieldsIsJSONAPI: PropTypes.arrayOf(PropTypes.bool),
    reload: PropTypes.arrayOf(PropTypes.bool),
    fieldsApiUrl: PropTypes.arrayOf(PropTypes.string),
    fieldsDisabled: PropTypes.arrayOf(PropTypes.bool),
    fieldsDisplayAttribute: PropTypes.arrayOf(PropTypes.string),
    fieldsSearchAttribute: PropTypes.arrayOf(PropTypes.string),
    secondFieldRelation: PropTypes.string,
    fieldsRequired: PropTypes.arrayOf(PropTypes.bool),
    fieldsValueType: PropTypes.arrayOf(PropTypes.string),
    buttonLabel: PropTypes.string,
    fieldsShowLabel: PropTypes.arrayOf(PropTypes.bool),
    fieldsShowPlaceholder: PropTypes.arrayOf(PropTypes.bool),
}

SelectGroup.propTypes = {
    ...SelectGroupMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dropup: PropTypes.bool,
}

SelectGroup.defaultProps = {
    fieldsDefaultOptions: [[], []],
    fieldsPlaceholder: ['', ''],
    fieldsEntityType: [null, null],
    fieldsParams: [{}, {}],
    fieldsSearchable: [false, false],
    fieldsIsJSONAPI: [undefined, undefined],
    fieldsApiUrl: [undefined, undefined],
    fieldsDisabled: [false, false],
    reload: [false, false],
    fieldsDisplayAttribute: ['name', 'name'],
    fieldsSearchAttribute: ['name', 'name'],
    secondFieldRelation: null,
    fieldsRequired: [false, false],
    fieldsValueType: [SELECT_VALUE_TYPE.OBJECT, SELECT_VALUE_TYPE.OBJECT],
    buttonLabel: 'button.add',
    fieldsShowLabel: [true, true],
    fieldsShowPlaceholder: [true, true],
    dropup: false,
}

export default SelectGroup
