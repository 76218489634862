import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'

import Separator from 'components/Separator'
import Image from 'components/Image'

const OrganizationInfo = ({ companyInvites }) => {
    const t = useTranslate()

    return (
        <Fragment>
            <span className="a-bodyTextRegular centered-text max-width -mt20 -mb20">
                {`${t('noAuth.invitedToJoinThePlatformAsPartOfOrganization')}:`}
            </span>
            {companyInvites.map(({ logoPath, name }, index) => (
                <div key={index} className="invitedByOrganization -mb15">
                    <div className="a-avatarIcon__wrapper">
                        <Image
                            path={logoPath}
                            alt="avatar"
                            icon={ICONS.BUILDING}
                        />
                    </div>
                    <span className="a-bodyTextMedium">{name}</span>
                </div>
            ))}
            <Separator />
        </Fragment>
    )
}

export default OrganizationInfo
