import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { editEntityService } from 'services/entity.service'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import { ProfilePictureUploadField } from 'components/formFields'
import Loader from 'components/Loader'
import Button from 'components/Button'
import ListOfStatusesWithTooltip from 'components/ListOfStatusesWithTooltip'
import Icon from 'components/Icon'
import ProfileImageGuidelinesModal from './ProfileGuidelinesModal/ProfileImageGuidelinesModal'

const DiverPhoto = ({ userData }) => {
    const navigate = useNavigate()

    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { userId, fetchCurrentUser } = useContext(CurrentUserContext)

    const [openModal, setOpenModal] = useState(false)

    const initialValues = {
        avatar: userData.avatar ?? null,
    }

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await editEntityService(ENTITIES.USER, userId, {
                ...userData,
                ...formData,
                finishedSignUp: true,
            })
            await fetchCurrentUser()
            navigate(ROUTES.HOME)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div className="m-form -register -mb20">
            <h1 className="-title -mb20">{t('general.addYourProfilePhoto')}</h1>
            <span className="a-bodyTextRegular a-lightText -mb50 a-centeredText">
                {t('general.addingPhoto')}
            </span>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, isSubmitting, ...formProps }) => (
                    <Form className="-diverPhoto">
                        <ProfilePictureUploadField
                            name="avatar"
                            signUp={true}
                            userName={
                                userData.firstName + ' ' + userData.lastName
                            }
                            positions={userData?.diverPositions}
                            location={userData.country}
                            handleSubmit={handleSubmit}
                            disabled={isSubmitting}
                            values={values}
                            formProps={formProps}
                            fileCategory={FILE_CATEGORIES.USER_AVATAR}
                        />
                        <p className="a-captionsTextRegular a-centeredText a-lightText -mt20">
                            {t('general.profileImageRequirements')}
                        </p>
                        <div className="-mt20">
                            <Button
                                label="general.profileImageGuidelines"
                                btnClass={BUTTON_STATUS.TERTIARY}
                                icon={ICONS.ARROW_RIGHT}
                                iconPosition={ICON_POSITION.RIGHT}
                                iconColor={COLORS.LIGHT_BLUE}
                                buttonSize={BUTTON_SIZE.XSMALL}
                                onClick={() => setOpenModal(true)}
                            />
                        </div>
                        <div className="column aligned-center -mt40">
                            <h3>
                                {userData.firstName + ' ' + userData.lastName}
                            </h3>
                            <div className="aligned-center -mt10 -location">
                                <Icon
                                    name={ICONS.LOCATION}
                                    color={COLORS.DARK_BLUE_20}
                                />
                                <span className="a-bodyTextRegular a-lightText -opacity-60">
                                    {userData.country.name}
                                </span>
                            </div>
                            <div className="-mt10 -positions aligned-center justify-center">
                                {userData?.diverPositions && (
                                    <ListOfStatusesWithTooltip
                                        statuses={Object.values(
                                            userData?.diverPositions
                                        )}
                                        count={3}
                                        statusColor="-purple"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="-mt30">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.join"
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
            {openModal && (
                <ProfileImageGuidelinesModal setOpenModal={setOpenModal} />
            )}
        </div>
    )
}

DiverPhoto.propTypes = {
    userData: PropTypes.object.isRequired,
}

export default DiverPhoto
